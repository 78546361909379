// eslint-disable-next-line no-unused-vars
import React from 'react';

export default function Legal(){
    return(
        <section>
            <div className='text-center'>
                <h1>Legal Page</h1>
            </div>
        </section>
    )
}