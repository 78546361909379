import React from "react";
import { Link } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import StickyBox from "react-sticky-box";
import { FaArrowRight } from "react-icons/fa";
import blog_1 from "./undraw_buy_house_re_8xq7-2.svg";
import blog_coming from "./undraw_coming_home_re_ausc.svg";

export default function Blog() {
  const apiUrl =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@frank_18938";
  return (
    <section>
      <Navbar />
      <div style={{ marginTop: "10rem" }} />
      <h1 className="mx-auto text-center mb-5">Read our blogs here</h1>
      <div className="row container mt-5 mx-auto">
        <div className="col-3 mx-auto market-card">
          <a href="https://medium.com/@frank_18938/step-onto-the-property-ladder-29aa2d803cc4">
            <section className="mb-5 rounded blog-embed">
              <div
                style={{
                  height: "10rem",
                  width: "100%",
                  backgroundImage: `url(${blog_1})`,
                  backgroundSize: "cover",
                  transition: "all 3s ease-in-out",
                }}
              />
              <p className="ps-0 mx-auto text-center ">
                In the last 10 years, house prices have grown 3 times faster
                than wages!
              </p>
              <div className="col-lg mb-4 row" style={{}}>
                <div className="col-4 text-center ">
                  <img
                    className="mb-2 shadow-lg bg-white"
                    src={blog_1}
                    style={{
                      height: "3rem",
                      marginTop: "5%",
                      borderRadius: "50%",
                      filter: "grayscale(100%)",
                    }}
                    alt=""
                  />
                  {/* <p className="fw-bold">{name}</p> */}
                </div>
                <p
                  className="col text-justify my-auto fw-bold"
                  style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    display: "table-cell",
                  }}
                >
                  Frank Teunissen
                  <br />
                </p>
              </div>
            </section>
          </a>
        </div>
        <div className="col-3 mx-auto market-card">
          <a href="https://medium.com/@frank_18938/step-onto-the-property-ladder-29aa2d803cc4">
            <section className="mb-5 rounded blog-embed">
              <div
                style={{
                  height: "10rem",
                  width: "100%",
                  backgroundImage: `url(${blog_1})`,
                  backgroundSize: "cover",
                  transition: "all 3s ease-in-out",
                }}
              />
              <p className="ps-0 mx-auto text-center ">
                In the last 10 years, house prices have grown 3 times faster
                than wages!
              </p>
              <div className="col-lg mb-4 row" style={{}}>
                <div className="col-4 text-center ">
                  <img
                    className="mb-2 shadow-lg bg-white"
                    src={blog_1}
                    style={{
                      height: "3rem",
                      marginTop: "5%",
                      borderRadius: "50%",
                      filter: "grayscale(100%)",
                    }}
                    alt=""
                  />
                  {/* <p className="fw-bold">{name}</p> */}
                </div>
                <p
                  className="col text-justify my-auto fw-bold"
                  style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    display: "table-cell",
                  }}
                >
                  Frank Teunissen
                  <br />
                </p>
              </div>
            </section>
          </a>
        </div>
        <div className="col-3 mx-auto market-card">
          <a href="https://medium.com/@frank_18938/step-onto-the-property-ladder-29aa2d803cc4">
            <section className="mb-5 rounded blog-embed">
              <div
                style={{
                  height: "10rem",
                  width: "100%",
                  backgroundImage: `url(${blog_1})`,
                  backgroundSize: "cover",
                  transition: "all 3s ease-in-out",
                }}
              />
              <p className="ps-0 mx-auto text-center ">
                In the last 10 years, house prices have grown 3 times faster
                than wages!
              </p>
              <div className="col-lg mb-4 row" style={{}}>
                <div className="col-4 text-center ">
                  <img
                    className="mb-2 shadow-lg bg-white"
                    src={blog_1}
                    style={{
                      height: "3rem",
                      marginTop: "5%",
                      borderRadius: "50%",
                      filter: "grayscale(100%)",
                    }}
                    alt=""
                  />
                  {/* <p className="fw-bold">{name}</p> */}
                </div>
                <p
                  className="col text-justify my-auto fw-bold"
                  style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    display: "table-cell",
                  }}
                >
                  Frank Teunissen
                  <br />
                </p>
              </div>
            </section>
          </a>
        </div>
      </div>
    </section>
  );
}
