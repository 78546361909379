import React from "react";
import logo from "./proprt-logo-new.png";
import {
  FaDiscord,
  FaTwitter,
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaRegUserCircle,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsMedium } from "react-icons/bs";
import { SiMedium } from "react-icons/si";
import img_real_estate from "./rea-logo-v4.png";
import img_qr from "./qr-code-8.png";
import img_opensea from "./opensea.svg";
import img_etherscan from "./etherscan-logo-light-circle.svg";
import "animate.css";

const handleVCFClick = (e) => {
  e.preventDefault();
  window.location.href = "https://proprt.io/Frank_Teunissen.vcf";
};

export default function Links() {
  return (
    <div
      className=""
      style={{
        width: "full",
        // minHeight: "90vh",
        // height: "100vh",
        // overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background:
          "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(52,137,219,1) 100%)",
        padding: "50px",
        // backgroundColor: "#",
      }}
    >
      <div
        id="title"
        style={{
          // color: "#E78200",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
        className="mb-4"
      >
        {/* <p className="fs-4 text-white mb-0 pb-0 fw-bold">PROPRT.io</p> */}
        <img
          src={logo}
          alt="logo"
          // style={{
          //   filter: "drop-shadow(3px -1px 2.5px rgba(255, 255, 255, 0.67))",
          // }}
          className="h-24 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite"
          onClick={() => {
            window.location.href = "/";
          }}
        />
      </div>
      <TheButton
        link={
          "https://www.realestate.com.au/news/buy-a-piece-of-a-luxury-gold-coast-property-from-220/"
        }
      >
        <p className=" my-auto">
          As seen on {` `}
          <img className="inline h-6" src={img_real_estate} alt="/" />
        </p>
      </TheButton>

      <TheButton link={"/Frank_Teunissen.vcf"}>
        <p className=" my-auto">
          Save Contact {` `}
          <FaRegUserCircle
            className="inline mx-auto my-auto"
            style={{ cursor: "pointer" }}
            size={20}
          />
        </p>
      </TheButton>

      <TheButton children={"Compare benefits"} link={"/benefits"} />

      <TheButton link={"https://opensea.io/collection/proprt-founder"}>
        <p className=" my-auto">
          View NFTs on {` `}
          <img className="inline h-[1.25rem]" src={img_opensea} alt="/" />
          {` `} OpenSea
        </p>
      </TheButton>
      <TheButton
        link={
          "https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4"
        }
      >
        <p className=" my-auto">
          Read more on {` `}
          <span className=" bg-black px-1.5 py-0.5 rounded-full text-white">
            <SiMedium className="inline h-[1.25rem] text-white mx-auto my-auto" />
            {` `}Medium
          </span>
        </p>
      </TheButton>

      <p id="motto" className="text-white text-center mt-4">
        Buy Real Property from $200
      </p>
      <div className="d-flex justify-content-between mt-3 gap-3 text-white">
        <FaDiscord
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://discord.gg/MgCe6Gzk2W");
          }}
          size={30}
        />
        <FaTwitter
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://twitter.com/proprt_io");
          }}
          size={30}
        />
        <FaFacebook
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://www.facebook.com/Proprtio-113952484660475");
          }}
          size={30}
        />
        <AiFillInstagram
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          size={30}
          onClick={() => {
            window.open("https://www.instagram.com/proprt.io/");
          }}
        />
        <FaLinkedin
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open("https://www.linkedin.com/company/proprt-io/");
          }}
          size={30}
        />
        <BsMedium
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4"
            );
          }}
          size={30}
        />
        <img
          src={img_etherscan}
          alt="etherscan"
          className="md:w-8 w-6 m-1 h-auto  flex-auto"
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://etherscan.io/token/0x978b22086fe90ffdfc509f30753c9f2211bb1de9?a=0x5169b464aaaa61506946bb54ba1d5dda883981ff"
            );
          }}
          width={30}
          color="white"
        />
      </div>

      <img
        src={img_qr}
        alt="qr code"
        className="h-40 mt-14 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite shadow-2xl rounded-3xl"
        // onClick={() => {
        //   window.location.href = "/";
        // }}
      />
    </div>
  );
}

const TheButton = ({ link, children, logo, animate }) => {
  return (
    <button
      className={
        "card my-3 p-3 border-0 shadow-2xl h-14 max-h-14 text-[#2276C9] hover:bg-[#2276C9] duration-300 hover:text-white rounded-md " +
        animate
      }
      style={{ width: "75vw", maxWidth: "500px" }}
      onClick={() => {
        window.location.href = link;
      }}
    >
      <div class="flex justify-between w-full content-center">
        <div>{logo && <img src={logo} alt="" className=" w-8 h-8" />}</div>
        <div>
          <p
            className=" my-auto font-semibold mx-auto md:text-base text-sm"
            // style={{
            //   color: "#2276C9",
            // }}
          >
            {children}
          </p>
        </div>
        <div className=""></div>
      </div>
    </button>
  );
};
