import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Landing from "./pages/landing";
import ReactDOMServer from "react-dom/server";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import {
  Mainnet,
  DAppProvider,
  useEtherBalance,
  useEthers,
  Config,
} from "@usedapp/core";

import { BrowserRouter } from "react-router-dom";

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://mainnet.infura.io/v3/62687d1a985d4508b2b7a24827551934",
  },
};

ReactDOM.render(
  <React.StrictMode>
    {/* <PayPalScriptProvider
      options={{
        "client-id":
          "ATnue5X6R8RSRJUq851In22raUKMQQTlhYJJcKB-p-xMn5ggUNK9YZsIk4gNolIlx0-B770Cuv0q-hKl",
        currency: "AUD",
        intent: "capture",
        locale: "en_AU",
      }}
    > */}
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </PayPalScriptProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);
// ReactDOMServer.renderToString(
//   <React.StrictMode>
//     <DAppProvider config={config}>
//       <App />
//     </DAppProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
