import React, { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import Axios from "axios";

export default function PayPage() {
  const bearerToken =
    "Bearer 49f2127700e29a47ab0283e8540a37cacd65ecc896dbccbd0abeb0d19c594cb6bc32834f675f3993b298f6f7b4611e788aac227866646ae3dd24ba583384fe553e6283532275c3bf0e4f20ce310831aac540ed6721a54bb5ef4a935acf3a2e81cfde047ebe6c366772d59584c9f33d0188f6f83871e7a00a125df9a7dcdaf88f";

  const url = "https://api1.proprt.io/api/auth/local/register";

  const mailerLite =
    "https://assets.mailerlite.com/jsonp/1318/forms/68736166163121394/subscribe";

  const [data, setData] = useState("");

  const [succeeded, setSucceeded] = useState(false);
  const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [billingDetails, setBillingDetails] = useState("");

  const [phoneData, setPhoneData] = useState({
    phone: 0,
    saved: false,
  });

  // handles when a payment is confirmed for paypal
  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (details) {
      const { payer } = details;
      setBillingDetails(payer);
      setSucceeded(true);
      console.log("Billing details", payer);
      const tempPassword = Math.random().toString(36).slice(-8);
      await Axios.post(
        url,
        {
          username: payer.email_address,
          email: payer.email_address,
          password: tempPassword,
          tempPassword: tempPassword,
          phone: phoneData.phone,
        },
        {
          headers: {
            Authorization: bearerToken,
          },
        }
      );
      await Axios.post(
        mailerLite,
        {
          fields: {
            email: payer.email_address,
            phone: phoneData.phone,
            "1stpass": tempPassword,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    });
  };

  return (
    <div className="flex h-screen bootstrap-off">
      <div className="my-auto mx-auto">
        {/* <div className="bg-white/10 rounded-full border border-gray-400 my-16 mx-auto lg:mx-0  focus-within:ring w-8/12 focus-within:border-blue-400 focus-within:ring-blue-300 focus-within:ring-opacity-40">
          <div className="flex flex-nowrap justify-between md:flex-row md:text-center">
            <input
              // type="number"
              className="p-2 m-1 text-sm w-full md:w-auto text-gray-600 bg-transparent appearance-none focus:outline-none focus:placeholder-transparent"
              placeholder="Mobile Number"
              onChange={(text) =>
                setPhoneData({ ...phoneData, phone: text.target.value })
              }
            />
            <button
              onClick={(e) =>
                setPhoneData({ ...phoneData, saved: !phoneData.saved })
              }
              className=" px-3 py-2 text-sm font-medium tracking-wider text-white transition-colors duration-200 transform bg-teal-600 rounded-full w-auto hover:bg-gray-700"
            >
              {phoneData.saved ? "Saved" : "Save"}
            </button>
          </div>
        </div> */}

        <form className="w-full max-w-sm mb-20 transition-all duration-300 ease-in-out">
          <div
            className={`flex items-center border-b py-2 ${
              phoneData.saved && phoneData.phone
                ? "border-yellow-400"
                : "border-teal-500"
            }`}
          >
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="tel"
              placeholder="Phone number"
              aria-label="Phone number"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              onChange={(text) =>
                setPhoneData({ ...phoneData, phone: text.target.value })
              }
              disabled={phoneData.saved}
            />
            <button
              className={`flex-shrink-0 ${
                phoneData.saved && phoneData.phone
                  ? "bg-yellow-400 text-slate-900"
                  : "bg-teal-500 text-white"
              } hover:bg-slate-500 border-teal-500 text-sm  py-1 px-2 rounded transition-all duration-300 ease-in-out`}
              type="button"
              onClick={(e) => {
                // setPhoneData({ ...phoneData, saved: !phoneData.saved });
                if (phoneData.phone) {
                  setPhoneData({ ...phoneData, saved: !phoneData.saved });
                }
              }}
            >
              {phoneData.saved && phoneData.phone ? "Saved" : "Save"}
            </button>
          </div>
        </form>

        {phoneData.saved && phoneData.phone ? (
          <PayPalButtons
            className="container mx-auto w-fit"
            createOrder={(data, actions) => {
              return actions.order
                .create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: "AUD",
                        value: "0.50",
                      },
                    },
                  ],
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                    brand_name: "proprt.io",
                    user_action: "PAY_NOW",
                    return_url: "https://proprt.io",
                    cancel_url: "https://proprt.io",
                    locale: "en-AU",
                  },
                  // payer: {
                  //   email_address: user.email,
                  //   name: {
                  //     given_name: user.given_name,
                  //     surname: user.family_name,
                  //   },
                  // },
                })
                .then((orderId) => {
                  // Your code here after create the order
                  return orderId;
                });
            }}
            // onApprove={function (data, actions) {
            //   return actions.order.capture().then(function () {
            //     // Your code here after capture the order
            //     // get payer email
            //     setData(data);
            //     console.log(data);
            //   });
            // }}
            onApprove={onApprove}
          />
        ) : (
          <></>
        )}
        {/* payer details */}
        {succeeded && (
          <div className="container mx-auto">
            <h2 className="text-2xl font-bold text-center">
              Thank you for your payment!
            </h2>
            <p className="text-center">
              Your payment was successful. You will receive an email
              confirmation shortly.
            </p>
            <div className="flex flex-col items-center">
              <div className="flex flex-col items-center">
                <h3 className="text-xl font-bold">Payer Details</h3>
                <p className="text-center">
                  Name: {billingDetails.name.given_name}{" "}
                  {billingDetails.name.surname}
                </p>
                <p className="text-center">
                  Email: {billingDetails.email_address}
                </p>
                {/* phone */}
                {billingDetails.phone && (
                  <p className="text-center">
                    Phone: {billingDetails.phone.phone_number.national_number}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <h1>{user.email}</h1> */}
    </div>
  );
}
