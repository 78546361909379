import React from "react";
import ReactTooltip from "react-tooltip";

export default function Agent() {
  return (
    <section className="text-gray-600 body-font overflow-hidden transition-all duration-500">
      <ReactTooltip id="REST" effect="solid">
        REST Token holders own the PROPR.io ecosystem and receive the fees
        generated from the platform.
      </ReactTooltip>
      <div className="container px-5 py-24 mx-auto">
        <h2 className="text-4xl title-font text-gray-500 tracking-widest text-center">
          Agents
        </h2>
        <p className="text-xl text-gray-500 text-center mt-2">
          Lets use an $800,000 property as an example to compare the old way
          with the PROPRT.io way :
        </p>

        <div className="lg:w-4/5 mx-auto flex flex-wrap mt-4">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
                Current System
              </h1>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Sales Price</span>
                <span className="ml-auto text-gray-900">$800,000</span>
              </div>
              <div className="flex border-gray-200 py-2 mb-0 mt-4">
                <p className="text-gray-900 text-lg title-font font-medium">
                  Sales-agent function&nbsp;
                  {/* <br /> */}
                  <span className=" text-sm fw-normal">
                    (working for the seller)
                  </span>
                </p>
                <span className="ml-auto text-gray-900 text-lg title-font font-medium">
                  Timeline
                </span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Advertising</span>
                <span className="ml-auto text-gray-900">2 weeks</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Listing</span>
                <span className="ml-auto text-gray-900">2 weeks</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Negotiation</span>
                <span className="ml-auto text-gray-900">weeks</span>
              </div>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Timeline to sale</span>
                <span className="ml-auto text-gray-900">months</span>
              </div>
              <div className="flex border-gray-200 py-2 mb-0 mt-4">
                <span className="text-gray-900 text-lg title-font font-medium">
                  Agent income
                </span>
                <span className="ml-auto text-gray-900 text-lg title-font font-medium">
                  $23,000
                </span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Recurring income</span>
                <span className="ml-auto text-gray-900">Nil</span>
              </div>
              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Income per day</span>
                <span className="ml-auto text-gray-900 fw-bold">$383 </span>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
                PROPRT.io
              </h1>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Sales Price</span>
                <span className="ml-auto text-gray-900">$800,000</span>
              </div>
              <div className="flex border-gray-200 py-2 mb-0 mt-4">
                <p className="text-gray-900 text-lg title-font font-medium">
                  Buyers-agent function&nbsp;
                  {/* <br /> */}
                  <span className=" text-sm fw-normal">
                    (working for the buyer)
                  </span>
                </p>
                <span className="ml-auto text-gray-900 text-lg title-font font-medium">
                  Timeline
                </span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Screening</span>
                <span className="ml-auto text-gray-900">1 day</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">On-Boarding</span>
                <span className="ml-auto text-gray-900">4 days</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Platform management</span>
                <span className="ml-auto text-gray-900">Nil</span>
              </div>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Timeline to sale</span>
                <span className="ml-auto text-gray-900">days</span>
              </div>
              <div className="flex border-gray-200 py-2 mb-0 mt-4">
                <span className="text-gray-900 text-lg title-font font-medium">
                  Agent income
                </span>
                <span className="ml-auto text-gray-900 text-lg title-font font-medium">
                  $8,000
                </span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Recurring income</span>
                <span className="ml-auto text-gray-900">PROPRT Tokens</span>
              </div>

              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Income per day</span>
                <span className="ml-auto text-gray-900">$2,000</span>
              </div>

              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Plus recurring fee
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  0.10%
                </span>
              </div>
              <div className="flex mt-3 text-[#2276C9]">
                <span className="title-font font-medium text-xl">
                  PLUS BONUS
                </span>
                <span className="flex ml-auto title-font font-medium text-xl">
                  <span className="fw-bold" data-tip data-for="REST">
                    REST
                  </span>
                  &nbsp; 5,000
                </span>
              </div>
            </div>
          </div>
          <p className="mt-10 space-y-1">
            Agents on PROPRT.io perform the following tasks
            <span className="fw-bold">&nbsp;HOW</span> the platform works.
            <ol className="list-decimal ml-7 space-y-1">
              <li>Screen, analyse and list sellers properties</li>
              <li>Monitor sales and ledger</li>
              <li>
                Find property managers and ensure smooth on-boarding to platform
              </li>
              <li>Monitor legal and regulatory frameworks</li>
            </ol>
          </p>
        </div>
      </div>
    </section>
  );
}
