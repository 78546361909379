import React from "react";
import Navbar from "../components/Navbar";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";

import testImg from "../img/first-property/39_Minnie_St_Ext_View_02_Low_Res_002.png";

import hero from "../img/LandingPage/hero02b_60grad.jpg";
import botomHero from "../img/Marketplace/houses_bg02.jpg";

import img1 from "../components/img/renders/39_Minnie_St_Ext_View_03_Low_Res_002.jpg";
import img2 from "../components/img/renders/39_Minnie_St_TH_06_ENS_Low_Res_002.jpg";
import img3 from "../components/img/renders/39_Minnie_St_TH_07_Living_01_Low_Res_003.jpg";
import img4 from "../components/img/renders/39_Minnie_St_TH_06_M_Bed_Low_Res_002.jpg";
import img5 from "../components/img/renders/39_Minnie_St_TH_08_Living_01_Low_Res_003.jpg";

import { BsFillFileEarmarkPdfFill } from "react-icons/bs";

import ic_left from "../img/icons/arrow-left-circle.svg";

import logo from "../img/logo/proprt_logo_final.png";

function PropertyInfoPage() {
  const isPc = useMediaQuery({ query: "(min-width: 1225px)" });
  const isTablet = useMediaQuery({ minWidth: 900.1, maxWidth: 1224 });
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <header>
        <link rel="icon" type="image/png" href={logo} />
      </header>
      <section
        style={{
          minHeight: "50rem",
          paddingTop: "7rem",
          backgroundImage: `url(${hero}), url(${botomHero})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top, bottom",
          paddingBottom: "10rem",
        }}
      >
        <Navbar />
        <div className="container mx-auto row pt-5 p-5" style={{}}>
          <div className=" p-0 row text-center mb-3 justify-content-between">
            <div className="col-md-3 text-start ps-0">
              <a
                href="https://proprt.io/marketplace"
                style={{
                  // fontWeight: "bold",
                  textAlign: "start",
                  color: "black",
                  paddingLeft: 0,
                  marginLeft: 0,
                }}
              >
                <img
                  src={ic_left}
                  alt=""
                  style={{ paddingLeft: 0, height: 20, marginLeft: 0 }}
                />
              </a>
            </div>
            <div className="col-md-3 text-start">
              <p className="p-0" style={{ fontWeight: 700 }}>
                39 Minnie St, Southport QLD 4215
              </p>
            </div>
            <div className="col-md-3 text-end me-0 pe-0">
              <p style={{ fontWeight: 700 }}>PROPRT ID: Minnie-1</p>
            </div>
          </div>
          <div className="container mb-5 justify-content-evenly mx-auto">
            <Slider {...settings}>
              <div
                className="bootstrap-off text-center center"
                style={{
                  alignContent: "center",
                  backgroundColor: "salmon",
                  width: "50vw",
                  height: "30vh",
                }}
              >
                <img
                  src={testImg}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="..."
                />
              </div>
              <div
                className="bootstrap-off text-center center"
                style={{
                  alignContent: "center",
                  backgroundColor: "salmon",
                  width: "50vw",
                  height: "30vh",
                }}
              >
                <img
                  src={img1}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="..."
                />
              </div>
              <div
                className="bootstrap-off text-center center"
                style={{
                  alignContent: "center",
                  backgroundColor: "salmon",
                  width: "50vw",
                  height: "30vh",
                }}
              >
                <img
                  src={img2}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="..."
                />
              </div>
              <div
                className="bootstrap-off text-center center"
                style={{
                  alignContent: "center",
                  backgroundColor: "salmon",
                  width: "50vw",
                  height: "30vh",
                }}
              >
                <img
                  src={img3}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="..."
                />
              </div>
              <div
                className="bootstrap-off text-center center"
                style={{
                  alignContent: "center",
                  backgroundColor: "salmon",
                  width: "50vw",
                  height: "30vh",
                }}
              >
                <img
                  src={img4}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="..."
                />
              </div>
              <div
                className="bootstrap-off text-center center"
                style={{
                  alignContent: "center",
                  backgroundColor: "salmon",
                  width: "50vw",
                  height: "30vh",
                }}
              >
                <img
                  src={img5}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="..."
                />
              </div>
            </Slider>
          </div>
          {/* first */}
          <div className="container p-0 bg-white rounded-3 shadow mt-3">
            <div
              className="container row mx-auto pt-md-1 pb-md-1 text-white rounded-top"
              style={{ backgroundColor: "#84D9C9" }}
            >
              <div className="col-9 p-0">
                <h5 className="m-0" style={{ fontWeight: 600 }}>
                  Investment
                </h5>
              </div>
              <div className="col p-0">
                {isMobile ? null : (
                  <div className="row text-end">
                    <h5 className="col-11 m-0 p-0" style={{ fontWeight: 600 }}>
                      Download Report
                    </h5>
                    <div className="col m-0 p-0 justify-content-end text-end align-items-end p-0">
                      <BsFillFileEarmarkPdfFill
                        size="20px"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row justify-content-around px-3">
              <div
                className="col-md-5 mt-3 pt-4 mb-3 pb-3 rounded mx-auto"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <h4 className="justify-content-around ps-4 fw-bold mb-4">
                  Investment Summary
                </h4>
                <div className="row justify-content-around text-black-50">
                  <div className="col-5">
                    <p>Yield (Gross)</p>
                    <p>Valuation</p>
                  </div>
                  <div className="col-4">
                    <p>4.7%</p>
                    <p>$990,000</p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-5 mt-3 pt-4 mb-3 pb-3 rounded m-5 mx-auto"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <h4 className="justify-content-around ps-4 fw-bold mb-4">
                  Investment Case
                </h4>
                <div className="row justify-content-around text-black-50">
                  <div className="col-5">
                    <p>Capital Growth (Historic)</p>
                    <p>PROPRT Price</p>
                  </div>
                  <div className="col-4">
                    <p>6.9%</p>
                    <br />
                    <p>$216.00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Second */}
          <div className="container p-0 bg-white rounded-3 shadow mt-5">
            <div
              className="container row mx-auto pt-md-1 pb-md-1 text-white rounded-top"
              style={{ backgroundColor: "#84D9C9" }}
            >
              <div className="col-9 p-0">
                <h5 className="m-0" style={{ fontWeight: 600 }}>
                  Monthly Report (example)
                </h5>
              </div>
              <div className="col p-0 text-end">
                {isMobile ? null : (
                  <select
                    className="selector text-white"
                    style={{ fontWeight: 600, fontSize: "20px" }}
                  >
                    <option selected> December</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                )}
              </div>
            </div>
            <div className="row justify-content-around px-3">
              <div
                className="col-md-5 mt-3 pt-4 mb-3 pb-3 rounded mx-auto"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <h4 className="justify-content-around ps-4 fw-bold mb-4">
                  Income
                </h4>
                <div className="row justify-content-around text-black-50">
                  <div className="col-5">
                    <p>Gross Rent</p>
                    <p>Net Rent</p>
                    <br />
                    <br />
                    <br />
                    <p style={{ color: "black", fontWeight: "bold" }}>
                      Income per PROPRT token
                    </p>
                  </div>
                  <div className="col-4">
                    <p>$3,780</p>
                    <p>$3,250</p>
                    <br />
                    <br />
                    <br />
                    <p style={{ color: "black", fontWeight: "bold" }}>$0.65</p>
                  </div>
                </div>
              </div>
              <div
                className="col-md-5 mt-3 pt-4 mb-3 pb-3 rounded m-5 mx-auto"
                style={{ backgroundColor: "#FAFAFA" }}
              >
                <h4 className="justify-content-around ps-4 fw-bold mb-4">
                  Expenses
                </h4>
                <div className="row justify-content-around text-black-50">
                  <div className="col-5">
                    {/* <p>Rental Expenses</p> */}
                    <p>Rates</p>
                    <p>Insurance</p>
                    <p>Management</p>
                  </div>
                  <div className="col-4">
                    {/* <p>$1,960</p> */}
                    <p>$240</p>
                    <p>$45</p>
                    <p>$245</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container p-0 pb-3 rounded-3 shadow mt-5 bg-white">
            <div
              className="container pt-md-1 pb-md-1 text-white rounded-top"
              style={{ backgroundColor: "#84D9C9" }}
            >
              <h5 className="m-0" style={{ fontWeight: 600 }}>
                PROPRT Marketplace (Demo only - not live yet, but watch this
                space!)
              </h5>
            </div>

            <div
              className="mt-3 pt-4 mb-3 pb-3 rounded ms-4 me-4 mx-auto"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <h4 className="text-center ps-4 fw-bold mb-5">Minnie-1</h4>
              <div className="row justify-content-end text-black p-0">
                <div className="col text-start p-0">
                  <p className="fw-bold text-center">BID</p>
                  <p className="bg-danger ps-1" style={{ marginLeft: "100%" }}>
                    <br />
                  </p>
                  <p className="bg-danger ps-1" style={{ marginLeft: "100%" }}>
                    <br />
                  </p>
                  <p
                    className="bg-success text-light ps-1"
                    style={{ marginLeft: "73%" }}
                  >
                    24
                  </p>
                  <p
                    className="bg-success text-light ps-1"
                    style={{ marginLeft: "83%" }}
                  >
                    17
                  </p>
                  <p
                    className="bg-success text-light ps-1"
                    style={{ marginLeft: "80%" }}
                  >
                    20
                  </p>
                </div>
                <div
                  className="bg-secondary text-light text-center p-0"
                  style={{ flex: 0.001 }}
                >
                  <p className="text-dark">
                    <br />
                  </p>
                  <p>220</p>
                  <p>218</p>
                  <p>216</p>
                  <p>214</p>
                  <p>212</p>
                </div>
                <div className="col text-end p-0">
                  <p className="fw-bold text-center">ASK</p>
                  <p
                    className="bg-danger text-light pe-1"
                    style={{ marginRight: "20%" }}
                  >
                    51
                  </p>
                  <p
                    className="bg-danger text-light pe-1"
                    style={{ marginRight: "30%" }}
                  >
                    43
                  </p>
                  <p
                    className="bg-danger text-light pe-1"
                    style={{ marginRight: "80%" }}
                  >
                    24
                  </p>
                  <p className="bg-success" style={{ marginRight: "110%" }}>
                    <br />
                  </p>
                  <p className="bg-info" style={{ marginRight: "110%" }}>
                    <br />
                  </p>
                </div>
              </div>
              <div className="row justify-content-evenly">
                <div className="col-md-2 mx-auto">
                  <button
                    className="portfolioButtonCyan bg-success"
                    type="button"
                    aria-expanded="false"
                    style={{ width: "100%" }}
                  >
                    Buy
                  </button>
                </div>
                <div className="col-md-2 mx-auto">
                  <button
                    className="portfolioButtonBlue bg-danger"
                    type="button"
                    aria-expanded="false"
                    style={{ width: "100%" }}
                  >
                    Sell
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PropertyInfoPage;
