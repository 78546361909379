import React from "react";
import ReactTooltip from "react-tooltip";

export default function Buyer() {
  return (
    <section className="text-gray-600 body-font overflow-hidden transition-all duration-500">
      <ReactTooltip id="REST" effect="solid">
        REST Token holders own the PROPR.io ecosystem and receive the fees
        generated from the platform.
      </ReactTooltip>
      <div className="container px-5 py-24 mx-auto">
        <section className="text-gray-600 body-font overflow-hidden transition-all duration-500 mb-4">
          <div className="container px-5 mx-auto">
            <h2 className="text-4xl title-font text-gray-500 tracking-widest text-center mb-4">
              Buyer's Benefits
            </h2>
            <p className="text-xl text-gray-500 text-center mt-2">
              Lets use an $800,000 property as an example to compare the old way
              with the PROPRT.io way :
            </p>

            <div className="hidden  md:flex flex-wrap mt-4 mx-20">
              <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                <div className="md:shadow-lg md:p-4 md:rounded-xl">
                  <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
                    Current System
                  </h1>
                  <div className="flex border-t  border-gray-200 py-2">
                    <span className="text-gray-500">Purchase whole house</span>
                  </div>
                  <div className="flex border-t  border-gray-200 py-2">
                    <span className="text-gray-500">
                      Pay <span className="font-bold">$80,000</span> deposit
                      (10%)
                    </span>
                  </div>
                  <div className="flex border-t border-gray-200 py-2">
                    <span className="text-gray-500">Pay stamp duty</span>
                  </div>
                  <div className="flex border-t border-b border-gray-200 py-2">
                    <span className="text-gray-500">
                      Arrange finance/mortgage
                    </span>
                  </div>
                </div>
              </div>
              {/* right */}
              <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                <div className="md:shadow-lg md:p-4 md:rounded-xl">
                  <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
                    PROPRT.io
                  </h1>
                  <div className="flex border-t  border-gray-200 py-2">
                    <span className="text-gray-500">
                      Buy 1 or more of 5,000 unique{" "}
                      <span className="font-bold">pieces</span> of property
                    </span>
                  </div>
                  <div className="flex border-t  border-gray-200 py-2">
                    <span className="text-gray-500">
                      Pay <span className="font-bold">$166</span> per piece{" "}
                      <span className="italic">(see calculation below)</span>
                    </span>
                  </div>
                  <div className="flex border-t border-gray-200 py-2">
                    <span className="text-gray-500">No stamp duty</span>
                  </div>
                  <div className="flex border-t border-b border-gray-200 py-2">
                    <span className="text-gray-500">No finance</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h2 className="text-3xl title-font text-gray-500 tracking-widest text-center mt-24 mb-4">
          Calculations:
        </h2>

        <div className="lg:w-4/5 mx-auto flex flex-wrap">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
                Current System
              </h1>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Sales Price</span>
                <span className="ml-auto text-gray-900">$800,000</span>
              </div>
              <p className="text-gray-900 text-lg title-font font-medium mb-0 mt-4">
                Expenses
              </p>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Stamp Duty</span>
                <span className="ml-auto text-gray-900">$30,000</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Legal</span>
                <span className="ml-auto text-gray-900">$1,000</span>
              </div>
              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Transfer Fees</span>
                <span className="ml-auto text-gray-900">$2,000</span>
              </div>
              <div className="flex mt-3">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Total Cost
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  $833,000
                </span>
              </div>
              <div className="flex border-gray-200 py-2">
                <span className="text-gray-500">Price per unit</span>
                <span className="ml-auto text-gray-900">$167.00</span>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
                PROPRT.io
              </h1>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Sales Price</span>
                <span className="ml-auto text-gray-900">$800,000</span>
              </div>
              <p className="text-gray-900 text-lg title-font font-medium mb-0 mt-4">
                Expenses
              </p>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Buyer's Agent</span>
                <span className="ml-auto text-gray-900">$8,000 </span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Legal</span>
                <span className="ml-auto text-gray-900">$1,000</span>
              </div>
              <div className="flex border-t border-b border-gray-200 py-2 mb-6">
                <span className="text-gray-500">Purchase Fee (2.5%)</span>
                <span className="ml-auto text-gray-900">$20,000 </span>
              </div>
              <div className="flex mt-3">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Total Cost
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  $830,000
                </span>
              </div>
              <div className="flex border-gray-200 py-2">
                <span className="text-gray-500">Price per unit</span>
                <span className="ml-auto text-gray-900">$166.00</span>
              </div>
              <div className="flex mt-3 text-[#2276C9]">
                <span className="title-font font-medium text-xl">
                  Bonus Tokens
                </span>
                <span className="flex ml-auto title-font font-medium text-xl">
                  <span className="fw-bold" data-tip data-for="REST">
                    REST
                  </span>
                  &nbsp;10,000
                </span>
              </div>
              <div className="flex border-gray-200">
                <span className="text-gray-500">(2 REST per PROPRT)</span>
              </div>
              <div className="flex border-gray-200 mt-3">
                <span className="text-gray-500">
                  Fees distributed to{" "}
                  <span className="fw-bold" data-tip data-for="REST">
                    REST
                  </span>{" "}
                  Token Holders
                </span>
              </div>
            </div>
          </div>
          <p className="mt-10 space-y-1">
            Benefits:
            <ol className="list-decimal ml-7 space-y-1">
              <li>Liquidity - Buy and Sell small portions at any time.</li>
              <li>Receive income from rent based on your ownership.</li>
              <li>Stamp Duty for transactions below $5,000 are zero.</li>
              <li>Buyers can step onto the property ladder.</li>
              <li>Ethereum Blockchain Secured.</li>
            </ol>
          </p>
        </div>
      </div>

      {/* 2nd */}
      <div className="container px-5 mx-auto pb-24">
        <h2 className="text-3xl title-font text-gray-500 tracking-widest text-center">
          Fees Compared:
        </h2>

        <div className="lg:w-4/5 mx-auto flex flex-wrap mt-4">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
                Current System
              </h1>
              {/* <h1 className="text-gray-500 text-sm title-font font-medium mb-4">
                Estimation fees
              </h1> */}

              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Stamp Duty</span>
                <span className="ml-auto text-gray-900">3.75%</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Legal</span>
                <span className="ml-auto text-gray-900">0.13%</span>
              </div>
              <div className="flex border-y mb-3 border-gray-200 py-2">
                <span className="text-gray-500">Transfer Fee</span>
                <span className="ml-auto text-gray-900">0.26%</span>
              </div>

              <div className="flex mt-4">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Total Fees <span className="text-base">*</span>
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  4.14%
                </span>
              </div>
              <div className="flex border-gray-200 mt-3">
                <span className="text-gray-500">*Fees are estimates only</span>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
                PROPRT.io
              </h1>

              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Stamp Duty</span>
                <span className="ml-auto text-gray-900 text-end">0.00%</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">
                  Legal (Covered by inital purchase)
                </span>
                <span className="ml-auto text-gray-900">0.00%</span>
              </div>
              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Platform Fee</span>
                <span className="ml-auto text-gray-900">2.50%</span>
              </div>
              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Total Fees
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  2.50%
                </span>
              </div>
              <div className="flex border-gray-200 mt-3">
                <span className="text-gray-500">
                  Fees distributed to{" "}
                  <span className="fw-bold" data-tip data-for="REST">
                    REST
                  </span>{" "}
                  Token Holders
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
