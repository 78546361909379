import React, { useState } from "react";
import axios from "axios";

export default function RegisterPage() {
  const bearerToken =
    "Bearer bfdd38edd3984101316c6b4fd805594dd4146b2e022862f415aeefe17a7e2c5a5a4d687adca1704f1a406a33dbfdd8bdb1b6721ed67f847ee845c795c4a2d0fbb392d7538714882f957d7aa68f3b702d2674c8398632e7d6737647278c1dbba0bec20ad65f40347da459bb45d9a797a2cbc8397707d59b398449a59013b99fa4";

  const url = "https://api1.proprt.io/api/auth/local/register";

  const addWallet = "https://api1.proprt.io/api/wallets";

  const mailerLite =
    "https://assets.mailerlite.com/jsonp/1318/forms/68736166163121394/subscribe";

  const generateWalletUrl = "https://ezythree.com/generate";

  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
    tempPassword: "",
    phone: 0,
    wallet: "",
  });

  const [loading, setLoading] = useState(false);

  // handles when a payment is confirmed for paypal
  const onApprove = async (data, actions) => {
    setLoading(true);

    // await fetch(generateWalletUrl, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // })
    //   .then((res) => {
    //     console.log("res", res);
    //     setUser({ ...user, wallet: res.address });
    //     console.log("user", user);
    //     return res.json();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   });
    // await axios
    //   .get(generateWalletUrl)
    //   .then((res) => {
    //     console.log("res", res);
    //     setUser({ ...user, wallet: res.address });
    //     console.log("user", user);
    //     return res.json();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   });

    try {
      const response = await axios.get(generateWalletUrl);
      console.log(response.data.address);
      setUser({ ...user, wallet: response.data.address });
      const walletRes = await axios.post(
        addWallet,
        {
          data: {
            publicAddress: response.data.address,
          },
        },
        {
          headers: {
            Authorization: bearerToken,
          },
        }
      );
      console.log("walletId", walletRes);

      await axios
        .post(
          url,
          {
            username: user.email,
            email: user.email,
            password: user.password,
            tempPassword: user.password,
            phone: user.phone,
            wallet: (await walletRes).data.data.id,
          },
          {
            headers: {
              Authorization: bearerToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
        });
      window.alert("You have successfully registered!");
    } catch (error) {
      console.log(error);
      window.alert("There was an error. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div className="flex h-screen bootstrap-off">
      {!loading ? (
        <div className="my-auto mx-auto">
          <form className="w-full max-w-sm mb-10 transition-all duration-300 ease-in-out">
            <div className={`flex items-center border-b py-2 border-teal-500`}>
              <input
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="tel"
                placeholder="Phone number"
                aria-label="Phone number"
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                onChange={(text) =>
                  setUser({ ...user, phone: text.target.value })
                }
              />
            </div>
            <div
              className={`flex items-center border-b mt-6 py-2 border-teal-500`}
            >
              <input
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="email"
                placeholder="Email"
                aria-label="Email"
                onChange={(text) =>
                  setUser({ ...user, email: text.target.value })
                }
              />
            </div>
            <div
              className={`flex items-center border-b mt-6 py-2 border-teal-500`}
            >
              <input
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="password"
                placeholder="Password"
                aria-label="Password"
                onChange={(text) =>
                  setUser({ ...user, password: text.target.value })
                }
              />
            </div>
          </form>
          <button
            className={`flex-shrink-0 bg-teal-500 text-white hover:bg-slate-500 border-teal-500 text-sm  py-1 px-2 rounded transition-all duration-300 ease-in-out`}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onApprove();
            }}
          >
            Register
          </button>
        </div>
      ) : (
        <div className="my-auto mx-auto">
          <div className="flex justify-center">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
          </div>
          <div className="flex justify-center">
            <h1 className="text-2xl">Loading...</h1>
          </div>
        </div>
      )}
    </div>
  );
}
