import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import hero from "../img/LandingPage/hero02b_60grad.jpg";
import logo from "../img/logo/proprt_logo_final.png";

import videoThumbnail from "../img/landingThumbnail.png";
import demoVideo from "../img/proprt vid final 021522.mp4";

import illustration_click from "../img/illustration/click_.png";
import illustration_own from "../img/illustration/own_.png";
import illustration_sell from "../img/illustration/sell_.png";
import illustartion_track from "../img/illustration/track_.png";

import Popup from "reactjs-popup";

import SendEmail from "./SendEmail";

import botomHero from "../img/Marketplace/houses_bg02.jpg";

export default function Header() {
  const [isShown01, setIsShown01] = useState(false);
  const [isShown02, setIsShown02] = useState(false);
  const [isShown03, setIsShown03] = useState(false);
  const [isShown04, setIsShown04] = useState(false);

  const isPc = useMediaQuery({ query: "(min-width: 1225px)" });
  const isBigTablet = useMediaQuery({ minWidth: 1000, maxWidth: 1439 });
  const isTablet = useMediaQuery({ minWidth: 700.1, maxWidth: 1224 });
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

  var [email, setEmail] = useState();

  function popUp(title, text) {
    return (
      <Popup
        open={true}
        modal
        onClose={() => {
          setIsShown01(false);
          setIsShown02(false);
          setIsShown03(false);
          setIsShown04(false);
        }}
      >
        <div
          className="container rounded shadow-lg p-5 pb-4 text-center"
          style={
            isPc
              ? {
                  width: "40%",
                  backgroundImage: `url(${botomHero})`,
                  backgroundSize: "100%",
                }
              : {
                  width: "auto",
                  backgroundImage: `url(${botomHero})`,
                  backgroundSize: "100%",
                }
          }
        >
          <h3
            style={{
              fontFamily: "Montserrat",
              fontWeight: "bold",
              color: "#4AB29D",
            }}
          >
            {title}
          </h3>
          <div className="pt-2">
            <p style={{ fontFamily: "Montserrat" }}>{text}</p>
          </div>
        </div>
      </Popup>
    );
  }

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div id="header" style={{ paddingTop: "13rem" }} className="mx-auto">
      {isMobile ? (
        <form
          className="mx-auto ps-3 pe-3 align-content-center justify-content-center"
          style={{ marginTop: "0rem" }}
          onSubmit={async (e) => {
            e.preventDefault();
            await fetch(
              "https://assets.mailerlite.com/jsonp/1318/forms/51049405316334751/subscribe",
              {
                method: "POST",
                body: JSON.stringify({
                  fields: {
                    email: email,
                  },
                }),
                headers: {
                  // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                if (data.success === false) {
                  console.log("error");
                  console.log(email);
                } else {
                  console.log("success");
                  console.log(email);
                }
              });
            alert(
              "Thank you. Confirmation email has been sent to you, please check your email."
            );
          }}
        >
          <h1 className="header-title">Micro Property Investing</h1>
          <p
            style={{ fontSize: "22px", fontFamily: "Montserrat" }}
            className="pt-4"
          >
            Step onto the property ladder from as little as $200.
            <br />
            Fractional Property ownership verified on Ethereum.
          </p>
          <div className="mx-auto ps-0">
            <input
              aria-label="email"
              aria-required="true"
              type="email"
              name="fields[email]"
              style={{ width: "100%", height: "2rem", margin: 0 }}
              className="form-control"
              id="inputPassword2"
              placeholder="Enter email address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="mx-auto ps-0 mt-3 mb-3">
            <button
              // type="button"
              id="landing-cyan-header-button"
              style={{
                fontSize: "15px",
                padding: 0,
                width: "100%",
                height: "2rem",
              }}
              className="col-6 headerButtonCyan"
              type="submit"
            >
              SIGN ME UP
            </button>
          </div>
          {/* <p style={{'fontSize': '14px', marginTop: '80px', 'fontWeight': '650', color: '#2276C9'}}>Learn more about proprt</p> */}

          <div className="col-lg mx-auto justify-content-center align-items-center">
            {/* <img id='intro-video' src={videoPlaceHolder} style={{'width': '85%',}}/> */}
            <HeaderPlayer />
          </div>
        </form>
      ) : (
        <form
          // action="https://assets.mailerlite.com/jsonp/1318/forms/51049405316334751/subscribe"
          // method="POST"
          // target="_blank"
          className="row ms-5 me-5 align-content-center justify-content-center pe-5 ps-5"
          style={{ marginTop: "0rem" }}
          onSubmit={async (e) => {
            e.preventDefault();
            await fetch(
              "https://assets.mailerlite.com/jsonp/1318/forms/51049405316334751/subscribe",
              {
                method: "POST",
                body: JSON.stringify({
                  fields: {
                    email: email,
                  },
                }),
                headers: {
                  // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then((data) => {
                console.log(data);
                if (data.success === false) {
                  console.log("error");
                  console.log(email);
                } else {
                  console.log("success");
                  console.log(email);
                }
              });
            alert(
              "Thank you. Confirmation email has been sent to you, please check your email."
            );
          }}
        >
          <div className="col-md pe-5">
            <h1 className="header-title">Micro Property Investing</h1>
            <p
              style={{ fontSize: "22px", fontFamily: "Montserrat" }}
              className="pt-4"
            >
              Step onto the property ladder from as little as $200.
              <br />
              Fractional Property ownership verified on Ethereum.
            </p>
            <div
              className="row mb-3 pe-5"
              style={{ margin: 0, padding: 0, marginTop: "3rem" }}
            >
              <p
                style={{ fontSize: "20px", fontFamily: "Montserrat" }}
                className="p-0 fw-bold mb-3"
              >
                Want free tokens via airdrops?
              </p>
              {isPc ? (
                <div className="col-md-8 m-0 ms-0 ps-0">
                  <input
                    aria-label="email"
                    aria-required="true"
                    type="email"
                    name="fields[email]"
                    style={{ width: "100%", height: "100%", margin: 0 }}
                    className="form-control"
                    id="inputPassword2"
                    placeholder="Enter email address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              ) : (
                <div
                  className="m-0 ms-0 me-0 p-0 ps-0"
                  style={{ width: "100%" }}
                >
                  <input
                    aria-label="email"
                    aria-required="true"
                    type="email"
                    name="fields[email]"
                    style={{ width: "100%", height: "100%", margin: 0 }}
                    className="form-control"
                    id="inputPassword2"
                    placeholder="Enter email address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              )}
              {isPc && (
                <button
                  type="submit"
                  id="landing-cyan-header-button"
                  className="col-md ps-0 pe-0 fw-bold headerButtonCyan"
                  style={{ fontSize: 15 }}
                >
                  SIGN ME UP
                </button>
              )}
              {isTablet && (
                <button
                  type="submit"
                  id="landing-cyan-header-button"
                  style={{
                    fontSize: "15px",
                    padding: 0,
                    height: "2rem",
                    width: "100%",
                  }}
                  className="col-4 mt-3 headerButtonCyan"
                >
                  SIGN ME UP
                </button>
              )}
              {/* {isBigTablet && <button type="button" id='landing-cyan-header-button' style={{'fontSize': '15px'}} className='col-6 bg-info headerButtonCyan'>Sign-up for updates</button> } */}
              <p
                style={{ fontSize: "20px", fontFamily: "Montserrat" }}
                className="p-0 mt-3"
              >
                Enter your email to learn more.
              </p>
            </div>
            {/* <p style={{'fontSize': '14px', marginTop: '80px', 'fontWeight': '650', color: '#2276C9'}}>Learn more about proprt</p> */}
          </div>
          <div className="col-lg mx-auto justify-content-center align-items-center">
            <HeaderPlayer />
          </div>
        </form>
      )}

      <hr
        className="line-section mx-auto"
        style={{ marginTop: "10%", marginBottom: "-4%" }}
      />

      {isShown01 &&
        popUp(
          "Select",
          "Choose from a range of investment grade properties  with different profiles to diversify your portfolio. Properties are carefully screened by our team of experts to maximise income and capital gains."
        )}
      {isShown02 &&
        popUp(
          "Buy",
          "Buy a unique piece of a specific property using your web3 wallet or fiat currency. Secure holdings in as many properties as you like."
        )}
      {isShown03 &&
        popUp(
          "Unique Token",
          "Your PROPRT Token is uniquely identified and secured on the Ethereum blockchain. You can store your PROPRT in your web3 wallet, your PROPRT.io dashboard, or keep it off-line (cold storage)."
        )}
      {isShown04 &&
        popUp(
          "Sell",
          "Control how and when you sell your PROPRT holdings, set the timing and minimum price. Or simply hold and receive your share of rental income."
        )}

      <div style={{ marginTop: "10%" }}>
        <div className="container text-center">
          <h1
            style={{
              color: "#2276C9",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
            className="text-3xl"
          >
            Property Investing Simplified
          </h1>
          <div className="row text-center mt-5 ">
            <div className="col-md-2 mx-auto" style={{}}>
              <img
                className=" headerIcons  mx-auto  mx-auto"
                src={illustration_click}
                alt="logo"
                onClick={() => {
                  setIsShown01(true);
                }}
              />
              <p className="mt-4" style={{ color: "grey" }}>
                Select a property
              </p>
            </div>
            <div className="col-md-2 mx-auto">
              <img
                className=" headerIcons  mx-auto"
                src={illustration_own}
                alt="logo"
                onClick={() => {
                  setIsShown02(true);
                }}
              />
              <p className="mt-4" style={{ color: "grey" }}>
                Buy a unique piece of a specific property
              </p>
            </div>
            <div className="col-md-2 mx-auto">
              <img
                className=" headerIcons  mx-auto"
                src={illustartion_track}
                alt="logo"
                onClick={() => {
                  setIsShown03(true);
                }}
              />
              <p className="mt-4" style={{ color: "grey" }}>
                Unique Digital Token (PROPRT) verifies ownership
              </p>
            </div>
            <div className="col-md-2 mx-auto">
              <img
                className=" headerIcons  mx-auto"
                src={illustration_sell}
                alt="logo"
                onClick={() => {
                  setIsShown04(true);
                }}
              />
              <p className="mt-4" style={{ color: "grey" }}>
                Sell all or part of your holdings at any time
              </p>
            </div>
          </div>
          <button
            type="button"
            className="headerButtonBlue mt-5 mx-auto"
            onClick={() => {
              window.open("https://proprt.io/benefits", "_self");
            }}
          >
            How it works
          </button>
        </div>
      </div>
    </div>
  );
}

const HeaderPlayer = () => {
  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/XvZBptrTB1g?rel=0&amp;enablejsapi=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

// https://www.youtube.com/embed/XvZBptrTB1g
