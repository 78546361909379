import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { useMediaQuery } from "react-responsive";
import { registerAsAgent, auth } from "../../components/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";

const RegistrationAgentPage = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 992 });

  const [agentDetails, setAgentDetails] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    agencyName: "",
    licenceNumber: "",
  });

  const [agencyName, setAgencyName] = useState("");

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const register = () => {
    if (!agentDetails.firstName) alert("Please enter name");
    registerAsAgent(
      agentDetails.firstName.toString(),
      agentDetails.lastName.toString(),
      agentDetails.email.toString(),
      agentDetails.password.toString(),
      agentDetails.mobileNumber.toString(),
      agentDetails.agencyName.toString(),
      agentDetails.licenceNumber.toString()
    );
  };

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard/portfolio");
  }, [user, loading]);

  var textInputStyle = {
    borderColor: "grey",
    borderWidth: "0.1px",
    borderRadius: "9px",
    width: "30%",
    height: "40px",
    minWidth: "9cm",
  };

  return (
    <div>
      <Navbar />
      <section
        style={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          textAlign: "center",
        }}
        className="pb-5"
      >
        <h1 style={{ fontWeight: "bold", paddingTop: "18vh" }}>
          Register as Agent
        </h1>
        <p>
          Want to register as a user instead?{" "}
          <a href="https://proprt.io/register" style={{ color: "#3F9987" }}>
            click here
          </a>
        </p>
        <div
          className="pt-3"
          style={{
            marginLeft: !isTabletOrMobile && "20vw",
            marginRight: !isTabletOrMobile && "20vw",
            padding: !isTabletOrMobile && "5vw",
            backgroundColor: !isTabletOrMobile && "#A2FCEA",
            borderRadius: !isTabletOrMobile && "10px",
            boxShadow:
              !isTabletOrMobile && "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            transition: "background-color 1.0s ease",
          }}
        >
          <h4 className="mt-4">Account Details</h4>
          <section className="row justify-content-center mt-3">
            <div className="col-xl">
              <p className="mb-0 ps-xl-1">First Name</p>
              <input
                className="login-input"
                type="text"
                placeholder="First name"
                style={textInputStyle}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-xl">
              <p className="mb-0 ps-xl-1">Last Name</p>
              <input
                className="login-input"
                type="text"
                placeholder="Last name"
                style={textInputStyle}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
          </section>

          <section className="row justify-content-center mt-4">
            <div className="col-xl">
              <p className="mb-0 ps-xl-1">Email</p>
              <input
                className="login-input"
                type="email"
                placeholder="Email"
                style={textInputStyle}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-xl">
              <p className="mb-0 ps-xl-1">Mobile Number</p>
              <input
                className="login-input"
                type="tel"
                placeholder="Mobile number"
                style={textInputStyle}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    mobileNumber: e.target.value,
                  })
                }
              />
            </div>
          </section>

          <section className="row justify-content-center mt-4">
            <div className="col-xl">
              <p className="mb-0 ps-xl-1">Password</p>
              <input
                className="login-input"
                type="password"
                placeholder="Password"
                style={textInputStyle}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    password: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-xl">
              {/* <p className="mb-0 ps-xl-1">Referral</p>
              <input
                className="login-input"
                type="password"
                placeholder="Referral code"
                style={textInputStyle}
              /> */}
            </div>
          </section>

          <h4 className="mt-5">Agency Details</h4>

          <section className="row justify-content-center mt-3">
            <div className="col-xl">
              <p className="mb-0 ps-xl-1">Business</p>
              <select
                style={textInputStyle}
                onChange={(e) => {
                  setAgencyName(e.target.value);
                  if (agencyName !== "Other") {
                    setAgentDetails({
                      ...agentDetails,
                      agencyName: e.target.value,
                    });
                  }
                }}
                className="login-input"
              >
                <option value={""}>Select Business:</option>
                <option value={"Ray White"}>Ray White</option>
                <option value={"Harcourts Coastal"}>Harcourts Coastal</option>
                <option value={"Firstnational"}>Firstnational</option>
                <option value={"Scott Coastal"}>Scott Coastal</option>
                <option value={"Professionals"}>Professionals</option>
                <option value={"LJ Hooker"}>LJ Hooker</option>
                <option value={"Rainer & Horne"}>Rainer & Horne</option>
                <option value={8}>Other</option>
              </select>
              {agencyName === "8" && (
                <input
                  className="login-input"
                  type="text"
                  placeholder="Agency name"
                  style={textInputStyle}
                  onChange={(e) => {
                    alert(e.target.value);
                    if (agencyName !== "Other") {
                      setAgentDetails({
                        ...agentDetails,
                        agencyName: e.target.value,
                      });
                    }
                  }}
                />
              )}
            </div>
            <div className="col-xl">
              <p className="mb-0 ps-xl-1">Licence Number</p>
              <input
                className="login-input"
                type="text"
                placeholder="Licence number"
                style={textInputStyle}
                onChange={(e) =>
                  setAgentDetails({
                    ...agentDetails,
                    licenceNumber: e.target.value,
                  })
                }
              />
            </div>
          </section>

          <section
            className="mt-4"
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input type="checkbox" className="bg-info" id="exampleCheck1" />
            <p className="my-auto ms-2">
              Become a proprt.io certified agent, I agree to{" "}
              <a href="#" style={{ color: "#3F9987" }}>
                terms & conditions.
              </a>
            </p>
          </section>

          <button
            id="landing-cyan-header-button"
            className="col-md mt-4 fw-bold headerButtonCyan"
            style={{ fontSize: 15 }}
            onClick={register}
          >
            Register
          </button>
        </div>
        <p className="mt-5">
          Have account?{" "}
          <a href="https://proprt.io/login" style={{ color: "#3F9987" }}>
            login here
          </a>
        </p>
      </section>
    </div>
  );
};

export default RegistrationAgentPage;
