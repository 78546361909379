import React from "react";
import ReactTooltip from "react-tooltip";

export default function Seller() {
  return (
    <section className="text-gray-600 body-font overflow-hidden transition-all duration-500">
      <ReactTooltip id="REST" effect="solid">
        REST Token holders own the PROPR.io ecosystem and receive the fees
        generated from the platform.
      </ReactTooltip>
      <div className="container px-5 py-24 mx-auto">
        <h2 className="text-4xl title-font text-gray-500 tracking-widest text-center">
          Sellers (Initial listing)
        </h2>
        <p className="text-xl text-gray-500 text-center mt-2">
          Lets use an $800,000 property as an example to compare the old way
          with the PROPRT.io way :
        </p>

        <div className="lg:w-4/5 mx-auto flex flex-wrap mt-4">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
                Current System
              </h1>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Sales Price</span>
                <span className="ml-auto text-gray-900">$800,000</span>
              </div>
              <p className="text-gray-900 text-lg title-font font-medium mb-0 mt-4">
                Expenses
              </p>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Agent Advertising</span>
                <span className="ml-auto text-gray-900">$3,000</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">
                  Seller's Agents Fees (2.5%)
                </span>
                <span className="ml-auto text-gray-900">$20,000</span>
              </div>
              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Legal</span>
                <span className="ml-auto text-gray-900">$1,000</span>
              </div>
              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Seller Receives
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  $776,000
                </span>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
                PROPRT.io
              </h1>
              <div className="flex border-t border-b border-gray-200 py-2">
                <span className="text-gray-500">Sales Price</span>
                <span className="ml-auto text-gray-900">$800,000</span>
              </div>
              <p className="text-gray-900 text-lg title-font font-medium mb-0 mt-4">
                Expenses
              </p>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Listing</span>
                <span className="ml-auto text-gray-900">$0</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Seller's Agents Fees</span>
                <span className="ml-auto text-gray-900">$0</span>
              </div>

              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Legal</span>
                <span className="ml-auto text-gray-900">$0</span>
              </div>
              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Seller Receives
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  $800,000
                </span>
              </div>
              <div className="flex mt-3 text-[#2276C9]">
                <span className="title-font font-medium text-xl">
                  Bonus Tokens
                </span>
                <span className="flex ml-auto title-font font-medium text-xl">
                  <span className="fw-bold" data-tip data-for="REST">
                    REST
                  </span>
                  &nbsp;5,000
                </span>
              </div>
            </div>
          </div>
          <p className="mt-10 space-y-1">
            Key Points:
            <ol className="list-decimal ml-7 space-y-1">
              <li>Advertising is done through PROPRT.io</li>
              <li>Buyers don't pay Stamp Duty on value less than $5,000</li>
              <li>Agents are as BUYERS agents - fees paid by buyers</li>
              <li>
                Own{" "}
                <span className="font-bold" data-tip data-for="REST">
                  REST
                </span>{" "}
                Tokens to collect your share of Platform Fees
              </li>
            </ol>
          </p>
        </div>
      </div>

      {/* 2nd */}
      <div className="container px-5 py-24 mx-auto">
        <h2 className="text-3xl title-font text-gray-500 tracking-widest text-center">
          Fees Compared:
        </h2>

        <div className="lg:w-4/5 mx-auto flex flex-wrap mt-4">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
                Current System
              </h1>

              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Agent Advertising</span>
                <span className="ml-auto text-gray-900">0.38%</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Seller's Agents Fees</span>
                <span className="ml-auto text-gray-900">2.50%</span>
              </div>
              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Legal</span>
                <span className="ml-auto text-gray-900">0.15%</span>
              </div>
              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Total Fees
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  3.03%
                </span>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <div className="md:shadow-lg md:p-4 md:rounded-xl">
              <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
                PROPRT.io
              </h1>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Listing</span>
                <span className="ml-auto text-gray-900">0.00%</span>
              </div>
              <div className="flex border-t border-gray-200 py-2">
                <span className="text-gray-500">Seller's Agents Fees</span>
                <span className="ml-auto text-gray-900">0.00%</span>
              </div>
              <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                <span className="text-gray-500">Legal</span>
                <span className="ml-auto text-gray-900">0.00%</span>
              </div>
              <div className="flex">
                <span className="title-font font-medium text-2xl text-gray-900">
                  Total Fees
                </span>
                <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                  0.00%
                </span>
              </div>
            </div>
          </div>
          <p className="mt-10 space-y-1">
            Key Points:
            <ol className="list-decimal ml-7 space-y-1">
              <li>Fees are paid by buyers</li>
            </ol>
          </p>
        </div>
      </div>
    </section>
  );
}

{
  /* <div className="lg:w-4/5 w-full lg:pr-10 lg:py-6 mb-6 mx-auto lg:mb-0">
<h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
  Current System
</h1>

<div className="flex border-gray-200 justify-between shadow-lg">
  <span className="text-gray-500 pl-10">
    <p className="py-2 text-xl fw-bold">
      <br />
    </p>
    <p className="py-2">Sales Price</p>
    <p className="py-2">Agent Advertising</p>
    <p className="py-2">Seller's Agents Fees (2.5%)</p>
    <p className="py-2">Legal</p>
  </span>
  <span className="flex space-x-24">
    <span className="text-gray-900 text-center">
      <p className="py-2 text-xl fw-bold border-b">Current System</p>
      <p className="py-2 border-b">$800,000</p>
      <p className="py-2 border-b">$1,000</p>
      <p className="py-2 border-b">$2,500</p>
    </span>
    <div className="text-gray-900 flex">
      <p className="py-2 my-auto fw-bold">VS</p>
    </div>
    <span className="text-gray-900 text-center pr-10">
      <p className="py-2 text-xl fw-bold text-[#2276C9] border-b">
        PROPRT.io
      </p>
      <p className="py-2 border-b">$800,000</p>
      <p className="py-2 border-b">$0</p>
      <p className="py-2 border-b">$0</p>
    </span>
  </span>
</div>
</div> */
}
