import React, { useState } from "react";
import Axios from "axios";

export default function LoginPage() {
  const bearerToken =
    "Bearer bfdd38edd3984101316c6b4fd805594dd4146b2e022862f415aeefe17a7e2c5a5a4d687adca1704f1a406a33dbfdd8bdb1b6721ed67f847ee845c795c4a2d0fbb392d7538714882f957d7aa68f3b702d2674c8398632e7d6737647278c1dbba0bec20ad65f40347da459bb45d9a797a2cbc8397707d59b398449a59013b99fa4";

  const url = "https://api1.proprt.io/api/auth/local/";

  const mailerLite =
    "https://assets.mailerlite.com/jsonp/1318/forms/68736166163121394/subscribe";

  const [user, setUser] = useState({
    identifier: "",
    password: "",
  });

  // handles when a payment is confirmed for paypal
  const onApprove = async (data, actions) => {
    await Axios.post(
      url,
      {
        identifier: user.identifier,
        password: user.password,
      },
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log("success");
          window.alert("You have successfully Logged in");
          window.location.href = "/dashboard";
        }
      })
      .catch((err) => {
        console.log("err", err);
        window.alert("There was an error. Please try again.");
      });
  };

  return (
    <div className="flex h-screen bootstrap-off">
      <div className="my-auto mx-auto">
        <form className="w-full max-w-sm mb-10 transition-all duration-300 ease-in-out">
          <div
            className={`flex items-center border-b mt-6 py-2 border-teal-500`}
          >
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="email"
              placeholder="Email"
              aria-label="Email"
              onChange={(text) =>
                setUser({ ...user, identifier: text.target.value })
              }
            />
          </div>
          <div
            className={`flex items-center border-b mt-6 py-2 border-teal-500`}
          >
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="password"
              placeholder="Password"
              aria-label="Password"
              onChange={(text) =>
                setUser({ ...user, password: text.target.value })
              }
            />
          </div>
        </form>
        <button
          className={`flex-shrink-0 bg-teal-500 text-white hover:bg-slate-500 border-teal-500 text-sm  py-1 px-2 rounded transition-all duration-300 ease-in-out`}
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            await onApprove();
          }}
        >
          Login
        </button>
      </div>
      {/* <h1>{user.email}</h1> */}
    </div>
  );
}
