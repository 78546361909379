import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import il_property_01 from "./img/39_Minnie_St_Ext_View_02_Low_Res_002.jpg";
import il_property_comingsoon from "./img/39_Minnie_St_Ext_View_01_Low_Res_002.png";

import Popup from "reactjs-popup";

export default function NewListing() {
  const [isShown01, setIsShown01] = useState(false);
  const [isShown02, setIsShown02] = useState(false);
  const [isShown03, setIsShown03] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const isPc = useMediaQuery({ query: "(min-width: 1225px)" });

  const [data, setData] = useState({
    email: "",
    name: "",
    value: "",
  });

  function popUp() {
    return (
      <Popup
        open={true}
        modal
        onClose={() => {
          setShowModal(false);
        }}
      >
        <form
          className="container bg-white rounded shadow-lg p-5 text-center"
          style={{
            backgroundSize: "100%",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
          onSubmit={async (e) => {
            e.preventDefault();
            if (data.email === "" || data.name === "" || data.value === "") {
              alert("Please fill everything.");
            } else {
              await fetch(
                "https://assets.mailerlite.com/jsonp/1318/forms/55423493200676580/subscribe",
                {
                  method: "POST",
                  body: JSON.stringify({
                    fields: {
                      email: data.email,
                      name: data.name,
                      total: data.value,
                    },
                  }),
                  headers: {
                    // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
                    "Content-Type": "application/json",
                  },
                }
              ).then((res) => res.json());
              alert("Thank you for your interest. We will contact you soon.");
            }
          }}
        >
          <h1
            style={{
              fontFamily: "Montserrat",
              color: "#4AB29D",
              fontWeight: "bold",
              maxWidth: "30rem",
            }}
          >
            Secure your piece of property
          </h1>
          <p>Please fill out this form below, and we will contact you soon.</p>
          <input
            className="login-input"
            type="email"
            placeholder="Email"
            style={{
              borderColor: "grey",
              borderWidth: "0.1px",
              borderRadius: "9px",
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
          />
          <br />
          <input
            className="login-input"
            type="text"
            placeholder="Name"
            style={{
              borderColor: "grey",
              borderWidth: "0.1px",
              borderRadius: "9px",
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
          <br />
          <input
            className="login-input"
            type="text"
            placeholder="How much would you like to invest?"
            style={{
              borderColor: "grey",
              borderWidth: "0.1px",
              borderRadius: "9px",
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setData({ ...data, value: e.target.value });
            }}
          />

          <div className="mx-auto pt-5">
            <button
              type="submit"
              style={{ backgroundColor: "#4AB29D", maxWidth: "10rem" }}
              className="btn-primary mx-auto custom-btn"
            >
              Register
            </button>
          </div>
        </form>
      </Popup>
    );
  }

  return (
    <>
      {showModal ? popUp() : null}
      {isMobile ? (
        <section className="mx-auto" style={{ marginTop: "11rem" }}>
          <hr
            className="line-section mx-auto"
            style={{ marginTop: "-6%", marginBottom: "6%" }}
          />
          <h1
            className="text-center"
            style={{ marginBottom: "0rem", color: "#2276C9" }}
          >
            Property Selection
          </h1>
          <p
            className="text-center mt-4"
            style={{ marginBottom: "0rem", color: "grey" }}
          >
            Benefit from a team of highly experienced investors, managers and
            professionals, <br />
            targeting a high yield and capital growth strategy. Here are our new
            listings.
          </p>
          <div
            className="row justify-content-center pb-5"
            style={{ width: "100%", margin: 0, padding: 0 }}
          >
            {/* MAIN */}
            <div className="col-lg-3 mx-auto">
              <div
                id="theCard"
                className="mx-auto bootstrap-off"
                style={{
                  width: "23rem",
                  height: "32rem",
                  borderRadius: "25px",
                  backgroundColor: "white",
                  marginTop: "5rem",
                  marginLeft: 0,
                  cursor: "pointer",
                  "-webkit-box-shadow": isShown02
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  "box-shadow": isShown02
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  transition: "0.3s",
                }}
                onMouseEnter={() => setIsShown02(true)}
                onMouseLeave={() => setIsShown02(false)}
              >
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "16px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "25px 25px 0px 0px",
                  }}
                >
                  <div className="col-10 mt-2">
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Minnie-1
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      39 Minnie St QLD
                    </p>
                  </div>
                  <div
                    className="col bootstrap-off"
                    style={{
                      backgroundColor: "#4AB29D",
                      height: "100%",
                      marginTop: 0,
                      borderRadius: "0px 25px 0px 0px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: "50%",
                        marginLeft: "15%",
                      }}
                    >
                      NEW
                    </p>
                  </div>
                </div>
                <div
                  className="bootstrap-off text-center center"
                  style={{
                    alignContent: "center",
                    backgroundColor: "salmon",
                    width: "100%",
                    height: "30%",
                  }}
                  onClick={() =>
                    window.open("https://proprt.io/properties/minnie-1")
                  }
                >
                  <img
                    src={il_property_01}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </div>
                <div
                  className="bootstrap-off"
                  style={{
                    alignContent: "center",
                    backgroundColor: "white",
                    width: "100%",
                    height: "42%",
                  }}
                >
                  <div
                    className="row text-center"
                    style={{ margin: 0, padding: 0, marginTop: "3px" }}
                  >
                    <div
                      className="col-6"
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Rental Yield
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Gross)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        4.7%
                      </p>
                    </div>
                    <div className="col-6" style={{ margin: 0, padding: 0 }}>
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Capital Gain
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Historic)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.9%
                      </p>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ margin: 0, padding: 0, marginTop: "20px" }}
                  >
                    <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                      PROPRT Price
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "400",
                        fontSize: 13.5,
                      }}
                    >
                      (Property Token)
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "700",
                        fontSize: 30,
                      }}
                    >
                      $216
                    </p>
                  </div>
                </div>
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                >
                  <div
                    id="buyNow"
                    className="col-6 text-center"
                    onClick={() => {
                      // setShowModal(true);
                      // loginWithRedirect();
                      window.open("/pay");
                    }}
                  >
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Buy Now
                    </p>
                  </div>
                  <div
                    id="viewDetail"
                    className="col-6 text-center"
                    onClick={() =>
                      window.open("https://proprt.io/properties/minnie-1")
                    }
                  >
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      View
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mx-auto ">
              <div
                id="theCard"
                className="mx-auto bootstrap-off"
                style={{
                  width: "23rem",
                  height: "32rem",
                  borderRadius: "25px",
                  backgroundColor: "white",
                  marginTop: "5rem",
                  marginLeft: 0,
                  cursor: "pointer",
                  "-webkit-box-shadow": isShown01
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  "box-shadow": isShown01
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  transition: "0.3s",
                }}
                onMouseEnter={() => setIsShown01(true)}
                onMouseLeave={() => setIsShown01(false)}
                onClick={() => {
                  alert("This property is coming soon. Stay tuned.");
                }}
              >
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "16px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "25px 25px 0px 0px",
                  }}
                >
                  <div className="col-10 mt-2">
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Minnie-2
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Southport QLD
                    </p>
                  </div>
                  <div
                    className="col bootstrap-off"
                    style={{
                      backgroundColor: "#4AB29D",
                      height: "100%",
                      marginTop: 0,
                      borderRadius: "0px 25px 0px 0px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: "50%",
                        marginLeft: "15%",
                      }}
                    >
                      NEW
                    </p>
                  </div>
                </div>
                <div
                  className="bootstrap-off text-center center"
                  style={{
                    alignContent: "center",
                    backgroundColor: "salmon",
                    width: "100%",
                    height: "30%",
                  }}
                >
                  <img
                    src={il_property_comingsoon}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </div>
                <div
                  className="bootstrap-off"
                  style={{
                    alignContent: "center",
                    backgroundColor: "white",
                    width: "100%",
                    height: "42%",
                  }}
                >
                  <div
                    className="row text-center"
                    style={{ margin: 0, padding: 0, marginTop: "3px" }}
                  >
                    <div
                      className="col-6"
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Rental Yield
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Gross)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        4.7%
                      </p>
                    </div>
                    <div className="col-6" style={{ margin: 0, padding: 0 }}>
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Capital Gain
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Historic)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.9%
                      </p>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ margin: 0, padding: 0, marginTop: "20px" }}
                  >
                    <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                      PROPRT Price
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "400",
                        fontSize: 13.5,
                      }}
                    >
                      (Property Token)
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "700",
                        fontSize: 30,
                      }}
                    >
                      $220
                    </p>
                  </div>
                </div>
                {/* <div className='bootstrap-off text-center' style={{'alignContent': 'center', backgroundColor: '#4AB29D', width: '100%', height: '13%', borderRadius: '0px 0px 25px 25px', paddingTop: '3.5%'}}>
                            <p style={{'fontSize': '20px', fontWeight: '600', color: 'white'}}>View Property</p>
                        </div> */}
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                >
                  <div id="buyNow" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Buy Now
                    </p>
                  </div>
                  <div id="viewDetail" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      View
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mx-auto">
              <div
                id="theCard"
                className="mx-auto bootstrap-off"
                style={{
                  width: "23rem",
                  height: "32rem",
                  borderRadius: "25px",
                  backgroundColor: "white",
                  marginTop: "5rem",
                  marginLeft: 0,
                  cursor: "pointer",
                  "-webkit-box-shadow": isShown03
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  "box-shadow": isShown03
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  transition: "0.3s",
                }}
                onMouseEnter={() => setIsShown03(true)}
                onMouseLeave={() => setIsShown03(false)}
                onClick={() => {
                  alert("This property is coming soon. Stay tuned.");
                }}
              >
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "16px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "25px 25px 0px 0px",
                  }}
                >
                  <div className="col-10 mt-2">
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Bris-1
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Brisbane QLD
                    </p>
                  </div>
                  <div
                    className="col bootstrap-off"
                    style={{
                      backgroundColor: "#4AB29D",
                      height: "100%",
                      marginTop: 0,
                      borderRadius: "0px 25px 0px 0px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: "50%",
                        marginLeft: "15%",
                      }}
                    >
                      NEW
                    </p>
                  </div>
                </div>
                <div
                  className="bootstrap-off text-center center"
                  style={{
                    alignContent: "center",
                    backgroundColor: "salmon",
                    width: "100%",
                    height: "30%",
                  }}
                >
                  <img
                    src={il_property_comingsoon}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </div>
                <div
                  className="bootstrap-off"
                  style={{
                    alignContent: "center",
                    backgroundColor: "white",
                    width: "100%",
                    height: "42%",
                  }}
                >
                  <div
                    className="row text-center"
                    style={{ margin: 0, padding: 0, marginTop: "3px" }}
                  >
                    <div
                      className="col-6"
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Rental Yield
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Gross)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.5%
                      </p>
                    </div>
                    <div className="col-6" style={{ margin: 0, padding: 0 }}>
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Capital Gain
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Historic)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.5%
                      </p>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ margin: 0, padding: 0, marginTop: "20px" }}
                  >
                    <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                      PROPRT Price
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "400",
                        fontSize: 13.5,
                      }}
                    >
                      (Property Token)
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "700",
                        fontSize: 30,
                      }}
                    >
                      $195
                    </p>
                  </div>
                </div>
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                >
                  <div id="buyNow" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Buy Now
                    </p>
                  </div>
                  <div id="viewDetail" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      View
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr
            className="line-section mx-auto"
            style={{ marginTop: "5.5rem" }}
          />
        </section>
      ) : (
        <section className="pe-5 ps-5 me-5 ms-5" style={{ marginTop: "11rem" }}>
          <hr
            className="line-section mx-auto"
            style={{ marginTop: "-6%", marginBottom: "6%" }}
          />
          <h1
            className="text-center text-3xl font-semibold"
            style={{ marginBottom: "0rem", color: "#2276C9" }}
          >
            Property Selection
          </h1>
          <p
            className="text-center mt-4"
            style={{ marginBottom: "0rem", color: "grey" }}
          >
            Benefit from a team of highly experienced investors, managers and
            professionals, <br />
            targeting a high yield and capital growth strategy. Here are our new
            listings.
          </p>
          <div
            className="row justify-content-center pb-5"
            style={{ width: "100%", margin: 0, padding: 0 }}
          >
            <div className="col-lg-3 mx-auto ">
              <div
                id="theCard"
                className="mx-auto bootstrap-off"
                style={{
                  width: "23rem",
                  height: "32rem",
                  borderRadius: "25px",
                  backgroundColor: "white",
                  marginTop: "5rem",
                  marginLeft: 0,
                  cursor: "pointer",
                  "-webkit-box-shadow": isShown01
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  "box-shadow": isShown01
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  transition: "0.3s",
                }}
                onMouseEnter={() => setIsShown01(true)}
                onMouseLeave={() => setIsShown01(false)}
                onClick={() => {
                  alert("This property is coming soon. Stay tuned.");
                }}
              >
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "16px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "25px 25px 0px 0px",
                  }}
                >
                  <div className="col-10 mt-2">
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Minnie-2
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Southport QLD
                    </p>
                  </div>
                  <div
                    className="col bootstrap-off"
                    style={{
                      backgroundColor: "#4AB29D",
                      height: "100%",
                      marginTop: 0,
                      borderRadius: "0px 25px 0px 0px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: "50%",
                        marginLeft: "15%",
                      }}
                    >
                      NEW
                    </p>
                  </div>
                </div>
                <div
                  className="bootstrap-off text-center center"
                  style={{
                    alignContent: "center",
                    backgroundColor: "salmon",
                    width: "100%",
                    height: "30%",
                  }}
                >
                  <img
                    src={il_property_comingsoon}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </div>
                <div
                  className="bootstrap-off"
                  style={{
                    alignContent: "center",
                    backgroundColor: "white",
                    width: "100%",
                    height: "42%",
                  }}
                >
                  <div
                    className="row text-center"
                    style={{ margin: 0, padding: 0, marginTop: "3px" }}
                  >
                    <div
                      className="col-6"
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Rental Yield
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Gross)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        4.7%
                      </p>
                    </div>
                    <div className="col-6" style={{ margin: 0, padding: 0 }}>
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Capital Gain
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Historic)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.9%
                      </p>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ margin: 0, padding: 0, marginTop: "20px" }}
                  >
                    <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                      PROPRT Price
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "400",
                        fontSize: 13.5,
                      }}
                    >
                      (Property Token)
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "700",
                        fontSize: 30,
                      }}
                    >
                      $220
                    </p>
                  </div>
                </div>
                {/* <div className='bootstrap-off text-center' style={{'alignContent': 'center', backgroundColor: '#4AB29D', width: '100%', height: '13%', borderRadius: '0px 0px 25px 25px', paddingTop: '3.5%'}}>
                        <p style={{'fontSize': '20px', fontWeight: '600', color: 'white'}}>View Property</p>
                    </div> */}
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                >
                  <div id="buyNow" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Buy Now
                    </p>
                  </div>
                  <div id="viewDetail" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      View
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* MAIN */}
            <div className="col-lg-3 mx-auto">
              <div
                id="theCard"
                className="mx-auto bootstrap-off"
                style={{
                  width: "23rem",
                  height: "32rem",
                  borderRadius: "25px",
                  backgroundColor: "white",
                  marginTop: "5rem",
                  marginLeft: 0,
                  cursor: "pointer",
                  "-webkit-box-shadow": isShown02
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  "box-shadow": isShown02
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  transition: "0.3s",
                }}
                onMouseEnter={() => setIsShown02(true)}
                onMouseLeave={() => setIsShown02(false)}
              >
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "16px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "25px 25px 0px 0px",
                  }}
                >
                  <div className="col-10 mt-2">
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Minnie-1
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      39 Minnie St QLD
                    </p>
                  </div>
                  <div
                    className="col bootstrap-off"
                    style={{
                      backgroundColor: "#4AB29D",
                      height: "100%",
                      marginTop: 0,
                      borderRadius: "0px 25px 0px 0px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: "50%",
                        marginLeft: "15%",
                      }}
                    >
                      NEW
                    </p>
                  </div>
                </div>
                <div
                  className="bootstrap-off text-center center"
                  style={{
                    alignContent: "center",
                    backgroundColor: "salmon",
                    width: "100%",
                    height: "30%",
                    // objectFit: "fill",
                  }}
                  onClick={() =>
                    window.open("https://proprt.io/properties/minnie-1")
                  }
                >
                  <img
                    src={il_property_01}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="property"
                  />
                </div>
                <div
                  className="bootstrap-off"
                  style={{
                    alignContent: "center",
                    backgroundColor: "white",
                    width: "100%",
                    height: "42%",
                  }}
                >
                  <div
                    className="row text-center"
                    style={{ margin: 0, padding: 0, marginTop: "3px" }}
                  >
                    <div
                      className="col-6"
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Rental Yield
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Gross)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        4.7%
                      </p>
                    </div>
                    <div className="col-6" style={{ margin: 0, padding: 0 }}>
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Capital Gain
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Historic)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.9%
                      </p>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ margin: 0, padding: 0, marginTop: "20px" }}
                  >
                    <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                      PROPRT Price
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "400",
                        fontSize: 13.5,
                      }}
                    >
                      (Property Token)
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "700",
                        fontSize: 30,
                      }}
                    >
                      $216
                    </p>
                  </div>
                </div>
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                >
                  <div
                    id="buyNow"
                    className="col-6 text-center"
                    onClick={(e) => {
                      // setShowModal(true);
                      // loginWithRedirect();
                      window.open("/pay");
                    }}
                  >
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Buy Now
                    </p>
                  </div>
                  <div
                    id="viewDetail"
                    className="col-6 text-center"
                    onClick={() =>
                      window.open("https://proprt.io/properties/minnie-1")
                    }
                  >
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      View
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mx-auto">
              <div
                id="theCard"
                className="mx-auto bootstrap-off"
                style={{
                  width: "23rem",
                  height: "32rem",
                  borderRadius: "25px",
                  backgroundColor: "white",
                  marginTop: "5rem",
                  marginLeft: 0,
                  cursor: "pointer",
                  "-webkit-box-shadow": isShown03
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  "box-shadow": isShown03
                    ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
                    : "5px 5px 15px 5px rgba(0,0,0,0.25)",
                  transition: "0.3s",
                }}
                onMouseEnter={() => setIsShown03(true)}
                onMouseLeave={() => setIsShown03(false)}
                onClick={() => {
                  alert("This property is coming soon. Stay tuned.");
                }}
              >
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    paddingLeft: "16px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "25px 25px 0px 0px",
                  }}
                >
                  <div className="col-10 mt-2">
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontWeight: "400",
                      }}
                    >
                      Bris-1
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      Brisbane QLD
                    </p>
                  </div>
                  <div
                    className="col bootstrap-off"
                    style={{
                      backgroundColor: "#4AB29D",
                      height: "100%",
                      marginTop: 0,
                      borderRadius: "0px 25px 0px 0px",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        marginTop: "50%",
                        marginLeft: "15%",
                      }}
                    >
                      NEW
                    </p>
                  </div>
                </div>
                <div
                  className="bootstrap-off text-center center"
                  style={{
                    alignContent: "center",
                    backgroundColor: "salmon",
                    width: "100%",
                    height: "30%",
                  }}
                >
                  <img
                    src={il_property_comingsoon}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt="..."
                  />
                </div>
                <div
                  className="bootstrap-off"
                  style={{
                    alignContent: "center",
                    backgroundColor: "white",
                    width: "100%",
                    height: "42%",
                  }}
                >
                  <div
                    className="row text-center"
                    style={{ margin: 0, padding: 0, marginTop: "3px" }}
                  >
                    <div
                      className="col-6"
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Rental Yield
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Gross)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.5%
                      </p>
                    </div>
                    <div className="col-6" style={{ margin: 0, padding: 0 }}>
                      <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                        Capital Gain
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "400",
                          fontSize: 13.5,
                        }}
                      >
                        (Historic)
                      </p>
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "700",
                          fontSize: 30,
                        }}
                      >
                        6.5%
                      </p>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ margin: 0, padding: 0, marginTop: "20px" }}
                  >
                    <p style={{ margin: 0, padding: 0, fontWeight: "600" }}>
                      PROPRT Price
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "400",
                        fontSize: 13.5,
                      }}
                    >
                      (Property Token)
                    </p>
                    <p
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: "700",
                        fontSize: 30,
                      }}
                    >
                      $195
                    </p>
                  </div>
                </div>
                <div
                  className="row bootstrap-off"
                  style={{
                    paddingTop: "0px",
                    backgroundColor: "#3489DB",
                    width: "100%",
                    height: "15%",
                    borderRadius: "0px 0px 25px 25px",
                  }}
                >
                  <div id="buyNow" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      Buy Now
                    </p>
                  </div>
                  <div id="viewDetail" className="col-6 text-center">
                    <p
                      style={{
                        marginTop: "15%",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "white",
                      }}
                    >
                      View
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr
            className="line-section mx-auto"
            style={{ marginTop: "5.5rem" }}
          />
        </section>
      )}
    </>
  );
}
