import React from "react";
import logo from "../img/logo/proprt_logo_final.png";
// import logo2 from "../img/logo/proprt_logo_silver_01.png";
import ic_account from "../img/icons/account.png";
// import ic_buy_token from "../img/icons/buy_token.png";
import ic_trend from "../img/icons/trend.png";
import ic_wallet from "../img/icons/wallet.png";
import ic_help from "../img/icons/help.png";
import ReactTooltip from "react-tooltip";

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top shadow">
      <div className="container-fluid">
        {/* <a className="navbar-brand" href="#">Navbar</a> */}
        <div style={{ display: "flex" }} className="">
          {/* height: 45 */}
          <a href="/">
            <img
              src={logo}
              style={{ marginRight: "15px", height: "50px" }}
              alt="logo"
            />
          </a>
          <a
            className="navbar-brand navbarMenu"
            style={{
              fontWeight: "bold",
              fontSize: "25px",
              color: "#113E63",
              fontFamily: "Montserrat",
            }}
            href="/"
          >
            proprt
          </a>

          {/* <input className="form-control custom-form" type="search" placeholder="Search" aria-label="Search"/> */}
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item ">
              <form className="ms-3 bootstrap-off d-md-flex pt-1">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
              </form>
            </li>
          </ul>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 block md:hidden">
            <li className="nav-item ">
              <div>
                <a
                  className="navbar-brand navbarMenu"
                  style={{
                    fontWeight: "bold",
                    fontSize: "25px",
                    color: "#113E63",
                    fontFamily: "Montserrat",
                  }}
                  href="https://ico.proprt.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  ICO Coming Soon
                </a>
              </div>
            </li>
          </ul>
          <ul className="nav" id="navbarMenu">
            <li className="nav-item">
              <a href="https://proprt.io/marketplace">
                <img
                  style={{ height: "43px" }}
                  className="nav-link"
                  data-tip
                  data-for="marketplace"
                  src={ic_trend}
                  alt="trend"
                />
              </a>
            </li>
            <li className="nav-item">
              <a href="/faq">
                <img
                  style={{ height: "43px" }}
                  className="nav-link"
                  data-tip
                  data-for="help"
                  src={ic_help}
                  alt="buy token"
                />
              </a>
            </li>
            <li className="nav-item">
              <a href="https://proprt.io/login">
                <img
                  style={{ height: "43px" }}
                  className="nav-link"
                  data-tip
                  data-for="login"
                  src={ic_account}
                  alt="account"
                />
              </a>
            </li>
            <li className="nav-item">
              <img
                style={{ height: "43px" }}
                className="nav-link"
                data-tip
                data-for="wallet"
                href="#scrollspyHeading2"
                src={ic_wallet}
                alt="wallet"
              />
            </li>
          </ul>
        </div>
      </div>

      <ReactTooltip id="marketplace" effect="solid" backgroundColor="#4AB29D">
        Marketplace
      </ReactTooltip>

      <ReactTooltip
        id="help"
        effect="solid"
        backgroundColor="#4AB29D"
        delayHide={1000}
        // delayShow={500}
        delayUpdate={500}
        className="tooltip justify-center text-center text-2xl transition-all duration-500"
      >
        <a href="/faq">
          <p className="hover:cursor-pointer">FAQ</p>
        </a>
        <a href="/benefits">
          <p className="hover:cursor-pointer">Benefits</p>
        </a>
        <a href="/faq">
          <p className="hover:cursor-pointer">How it works</p>
        </a>
      </ReactTooltip>

      <ReactTooltip id="login" effect="solid" backgroundColor="#4AB29D">
        Account
      </ReactTooltip>

      <ReactTooltip id="wallet" effect="solid" backgroundColor="#4AB29D">
        Wallet
      </ReactTooltip>
    </nav>
  );
}
