import React from "react";

import img_bg from "./R-BG.png";
import img_logo from "./H-logo.png";

export default function HarmonyPage() {
  return (
    <div
      className=" bg-[#020C1D] min-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        width: "full",
        backgroundImage: `url(${img_bg})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "50px",
        paddingTop: "20px",
      }}
    >
      {/* <img
        src={img_logo}
        alt="logo"
        style={{
          position: "absolute", // Add this line
          top: 20, // Add this line
          left: 20, // Add this line
        }}
        className="h-24 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite"
        onClick={() => {
          window.location.href = "/";
        }}
      /> */}
      <div
        id="title"
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
        className="mb-4"
      >
        <img
          src={img_logo}
          alt="logo"
          className="h-20 mx-auto cursor-pointer animate__animated animate__slower 2s animate__pulse animate__infinite	infinite"
          onClick={() => {
            window.location.href = "/";
          }}
        />

        <button
          className={
            "card mt-4 w-full px-10 bg-[#020C1D] border-2 border-[#FFA700] shadow-[#cfbe9e] shadow-md h-10 text-white hover:scale-105 duration-300 rounded-md "
          }
          onClick={() => {
            window.location.href = "harmony-docs/HARMONY_ESTATE_VERSION_1.pdf";
          }}
        >
          <div class="flex items-center justify-center w-full my-auto ">
            <div>
              <p className="my-auto font-semibold md:text-sm text-sm">
                Information Memorandum
              </p>
            </div>
          </div>
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-20 gap-y-0">
        <div>
          <TheButton
            link={"harmony-docs/(1)_Lismore_Growth_&_Realignment_Strategy.pdf"}
          >
            <p className=" my-auto">1. Lismore Growth & Realignment Strategy</p>
          </TheButton>
          <TheButton link={"harmony-docs/(2)_URBIS_Urban_Design_Report.pdf"}>
            <p className=" my-auto">2. URBIS Urban Design Report</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(3)_Appendix_G_Heritage_Due_Diligence_Assessment_ FINAL.pdf"
            }
          >
            <p className=" my-auto">3. Heritage Due Diligence Assessment</p>
          </TheButton>
          <TheButton
            link={"harmony-docs/(4)_URBIS_Economic_Benefits_Assessment.pdf"}
          >
            <p className=" my-auto">4. URBIS Economic Benefits Assessment</p>
          </TheButton>
          <TheButton
            link={"harmony-docs/(5)_Social_Infrastructure_Needs_Assessment.pdf"}
          >
            <p className=" my-auto">
              5. Social Infrastructure Needs Assessment
            </p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(6)_Appendix_H_-_Ecological_Assessment_Report_V1.pdf"
            }
          >
            <p className=" my-auto">6. Ecological Assessment Report</p>
          </TheButton>
          <TheButton
            link={"harmony-docs/(7)_Planning_Proposal_Presentation.pdf"}
          >
            <p className=" my-auto">
              7. Lismore Planning Proposal Presentation
            </p>
          </TheButton>
          <TheButton
            link={"harmony-docs/(8)_URBIS_Indicative_Staging_Plan.pdf"}
          >
            <p className=" my-auto">8. URBIS Indicative Staging Plan</p>
          </TheButton>
          <TheButton
            link={"harmony-docs/(9)_Appendix_H_-_Ecological_Assessment.pdf"}
          >
            <p className=" my-auto">9. Ecological Assessment</p>
          </TheButton>
        </div>

        <div>
          <TheButton
            link={"harmony-docs/(10)_Appendix_I_-_Agricultural_Assessment.pdf"}
          >
            <p className=" my-auto">10. Agricultural Assessment</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(11)_Appendix_J_-_Land_Use_Conflict_Risk_Assessment_V1.pdf"
            }
          >
            <p className=" my-auto">11. Land Use Conflict Risk Assessment</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(12)_Appendix_K_-_Bushfire_Constraints_Assessment.pdf"
            }
          >
            <p className=" my-auto">12. Bushfire Constraints Assessment</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(13)_Appendix_L_-_Preliminary_Site_Investigation.pdf"
            }
          >
            <p className=" my-auto">13. Preliminary Site Investigation</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(14)_Appendix_M_-_Acid_Sulfate_Soil_Investigation.pdf"
            }
          >
            <p className=" my-auto">14. Acid Sulfate Soil Investigation</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(15)_Appendix_N_-_Noise_Impact_Assessment_V1.pdf"
            }
          >
            <p className=" my-auto">15. Noise Impact Assessment</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(16)_Appendix_O_-_Geotechnical_Investigations_Report.pdf"
            }
          >
            <p className=" my-auto">16. Geotechnical Investigations Report</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(17)_Appendix_P_-_Traffic_and_Transport_Study__FINAL.pdf"
            }
          >
            <p className=" my-auto">17. Traffic and Transport Study</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(18)_Appendix_Q_-_Water_Servicing_Assessment_(Engeny)___FINAL.pdf"
            }
          >
            <p className=" my-auto">18. Water Servicing Assessment (Engeny)</p>
          </TheButton>
        </div>

        <div>
          <TheButton
            link={
              "harmony-docs/(19)_Appendix_R_-_Sewer_Servicing_Assessment_(H2One)___FINAL.pdf"
            }
          >
            <p className=" my-auto">19. Sewer Servicing Assessment (H2One)</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(20)_Appendix_S__BRS_Stormwater_Management_Report__FINAL.pdf"
            }
          >
            <p className=" my-auto">20. Stormwater Management Report</p>
          </TheButton>
          <TheButton link={"harmony-docs/(21)_Appendix_T_-_Site_Survey.pdf"}>
            <p className=" my-auto">21. Site Survey</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(22)_Appendix_V_-__NCRP_-_Urban_Growth_Variation_Principles.pdf"
            }
          >
            <p className=" my-auto">22. Urban Growth Variation Principles</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(23)_Appendix_W_-_NCRP_-_Important_Farmland_Variation_Criteria.pdf"
            }
          >
            <p className=" my-auto">
              23. Important Farmland Variation Criteria
            </p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(24)_Appendix_X_-_Consistency_with_applicable_State_and_regional_studies.pdf"
            }
          >
            <p className=" my-auto">24. State and Regional Studies</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(25)_Appendix_Y_-_Consistency_with_the_SEPPS.pdf"
            }
          >
            <p className=" my-auto">25. Consistency with the SEPPS</p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(26)_Appendix_Z_-_Consistency_with_the_Section_9.11_Directions.pdf"
            }
          >
            <p className=" my-auto">
              26. Consistency with the Section 9.11 Directions
            </p>
          </TheButton>
          {/* <TheButton
            link={
              "harmony-docs/(27)_Detailed_Concept_Plans_1055_Bruxner_Part_A.pdf"
            }
          >
            <p className=" my-auto">
              27. Detailed Concept Plans 1055 Bruxner Part A
            </p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(28)_Detailed_Concept_Plans_1055_Bruxner_Part_B.pdf"
            }
          >
            <p className=" my-auto">
              28. Detailed Concept Plans 1055 Bruxner Part B
            </p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(29)_Detailed_Concept_Plans_1055_Bruxner_Part_C.pdf"
            }
          >
            <p className=" my-auto">
              29. Detailed Concept Plans 1055 Bruxner Part C
            </p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/(30)_Detailed_Concept_Plans_1055_Bruxner_Part_D.pdf"
            }
          >
            <p className=" my-auto">
              30. Detailed Concept Plans 1055 Bruxner Part D
            </p>
          </TheButton>
          <TheButton
            link={
              "harmony-docs/32.Residential_MHE_-_Earthworks_and_Slope_Analysis.pdf"
            }
          >
            <p className=" my-auto">31. Earthworks and Slope Analysis</p>
          </TheButton> */}
        </div>
      </div>
    </div>
  );
}
const TheButton = ({ link, children, logo, animate }) => {
  return (
    <button
      className={
        "card my-2 w-full bg-[#FFA700] shadow-2xl h-7 text-[#020C1D] hover:scale-105 duration-300 rounded-md " +
        animate
      }
      onClick={() => {
        window.location.href = link;
      }}
    >
      <div class="flex justify-start w-full content-center text-left my-auto mx-2">
        <div>
          <p className=" my-auto font-semibold md:text-xs text-[10px]">
            {children}
          </p>
        </div>
        <div className=""></div>
      </div>
    </button>
  );
};
