import React, { useEffect } from "react";
import xMark from "../img/icons/xmarkv2_red.png";
import checkMark from "../img/icons/check_markv2.png";

import hero from "../img/LandingPage/hero02b_60grad.jpg";
import botomHero from "../img/Marketplace/houses_bg02.jpg";
import Navbar from "../components/Navbar";

export default function Comparison() {
  useEffect(() => {
    document.title = "Comparison";
  });
  return (
    <section
      style={{
        backgroundImage: `url(${hero}), url(${botomHero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "top, bottom",
      }}
    >
      <Navbar />
      <div
        className="text-center justify-content-md-center row"
        style={{ backgroundColor: "", paddingTop: "9rem" }}
      >
        <h1 style={{ fontSize: "40px", fontWeight: "800", opacity: "0.7" }}>
          Comparison
        </h1>
      </div>
      <div className="container text-center mx-auto row pt-5 p-5">
        <div className="bg-light p-5 shadow-lg" id="original-comparison-table">
          <div
            className="row"
            id="comparison-table-header"
            style={{ marginBottom: "-2rem" }}
          >
            <div className="col-6 text-start">
              <p id="feature-landing-title">FEATURES</p>
            </div>
            <div className="col-2">
              <p>Traditional Ownership</p>
            </div>
            <div className="col-2 content-center">
              <p className="mx-auto">REIT*</p>
            </div>
            <div className="col-2">
              <p>PROPRT Platform</p>
            </div>
          </div>
          {comparisonRowList()}
          <div className="text-start mt-5 pt-0">
            <p>*REIT - Real Estate Investment Trust</p>
            <p>
              *Risk refers to execution risks for inexperienced property
              investors
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

var comparisonRowList = () => {
  var dataList = [];
  dataList.push(["Blockchain", null]);
  dataList.push(["Low fees", false, false, true]);
  dataList.push(["Funds immediately accessible", false, false, true]);
  dataList.push(["Buy with crypto", false, false, true]);
  dataList.push(["Blockchain secured", false, false, true]);
  dataList.push(["Low exit fees", false, false, true]);
  dataList.push(["Reduced third party risk", false, false, true]);

  dataList.push(["Fractional Property", null]);
  dataList.push(["Low capital required", false, true, true]);
  dataList.push(["Hassle- free", false, true, true]);
  dataList.push(["Liquidity (easy to sell)", false, true, true]);
  dataList.push(["No mortgage required", false, true, true]);
  dataList.push(["Build portfolio incrementally", false, true, true]);
  dataList.push(["Less risk*", false, true, true]);
  dataList.push(["Could live in property", false, true, true]);

  dataList.push(["Property", null]);
  dataList.push(["Transparency", true, false, true]);
  dataList.push(["Control property selection", true, false, true]);
  dataList.push(["Residential property focus", true, false, true]);
  dataList.push(["Control of sale price", true, false, true]);
  dataList.push(["Ability to learn property investing", true, false, true]);

  dataList.push(["DeFi", null]);
  dataList.push(["Financial derivatives available (DeFi)", false, false, true]);
  dataList.push(["Store off-line", false, false, true]);
  dataList.push(["Extra income & capital gains", false, false, true]);
  dataList.push(["Offline storage of asset", false, false, true]);
  dataList.push(["Lend, borrow and control asset", false, false, true]);

  var returnValue = [];
  for (var i = 0; i < dataList.length; i++) {
    var feature = dataList[i][0];
    var traditionalOwnership = dataList[i][1];
    var reit = dataList[i][2];
    var proprtio = dataList[i][3];

    // comparisonTableRow(feature, traditionalOwnership, reit, proprtio);
    returnValue.push(
      comparisonTableRow(feature, traditionalOwnership, reit, proprtio)
    );
  }

  return returnValue;
};

function comparisonTableRow(feature, traditionalOwnership, reit, proprtio) {
  if (traditionalOwnership !== null) {
    return (
      <div className="row mb-4">
        <div className="col-6 text-start">
          <p>{feature}</p>
        </div>
        <div className="col-2">
          <img
            alt=""
            src={traditionalOwnership ? checkMark : xMark}
            className="comparison-icons-original"
          />
        </div>
        <div className="col-2">
          <img
            alt=""
            src={reit ? checkMark : xMark}
            className="comparison-icons-original"
          />
        </div>
        <div className="col-2">
          <img
            alt=""
            src={proprtio ? checkMark : xMark}
            className="comparison-icons-original"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="row mb-4 mt-5">
        {/* <div className="col-6 text-start">
          <p style={{ fontSize: 19 }}>&nbsp;</p>
        </div> */}
        <div className="col-6 text-start">
          <p
            className="fw-300 align-middle my-auto"
            style={{ color: "#2276c9" }}
          >
            {feature}
          </p>
        </div>
      </div>
    );
  }
}
