import React, { useEffect, useState } from "react";

import Navbar from "../components/Navbar";
import Header from "../components/Header";
import NewListing from "../components/NewListing";
import MailingRegistration from "../components/MailingRegistration";
import Footer from "../components/Footer";
import QuickArticles from "../components/QuickArticles";
import FaqCarousel from "../components/FaqCarousel";
import TeamMember from "../components/TeamMember";

import hero from "../img/LandingPage/hero02b_60grad.jpg";
import botomHero from "../img/Marketplace/houses_bg02.jpg";
import illustration from "../img/illustration/popup.svg";
import logoPopup from "../img/logo/Comp_3.gif";
import img_angledCards from "../img/PROPRT(Angled).png";
import { useMediaQuery } from "react-responsive";

import Popup from "reactjs-popup";
// import 'reactjs-popup/dist/index.css';

export default function Landing() {
  const [showModal, setShowModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  useEffect(() => {
    setTimeout(() => setShowModal(false), 20000);
  }, []);

  const [data, setData] = useState({});

  function popUp() {
    return (
      <Popup
        open={true}
        modal
        onClose={() => {
          setShowModal(false);
        }}
      >
        <form
          className="container shadow-lg ps-5 pe-5 text-center"
          style={{
            backgroundSize: "100%",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            opacity: "1",
            borderRadius: "25px",
            backgroundImage: `url(${hero})`,
          }}
        >
          <div className="container  justify-content-between">
            <img
              src={img_angledCards}
              alt="logo"
              className="img-fluid mb-4"
              style={{
                "-webkit-filter": "drop-shadow(5px 5px 5px #222)",
                filter: "drop-shadow(5px 5px 5px #222)",
                maxWidth: "400px",
                width: "45vw",
              }}
            />
            <h3>Purchase your Founder NFT now! </h3>
            <p
              style={{
                maxWidth: "400px",
              }}
            >
              Receive an allocation of Real Estate (REST) Tokens with the NFT
              purchase. This limited release series gives owners the first right
              to purchase new listings of fractionalised property.
            </p>
            <button
              onClick={() =>
                window.open("https://opensea.io/collection/proprt-founder")
              }
              style={{
                backgroundColor: "#2373c9",
                maxWidth: "10rem",
                borderRadius: 0,
                paddingLeft: "10px",
                paddingRight: "10px",
                color: "#000000",
              }}
              className="btn-primary mx-auto custom-btn mb-5 mt-4 rounded-3 text-white"
            >
              Buy on OpenSea
            </button>
          </div>
        </form>
      </Popup>
    );
  }

  return (
    <div>
      <Navbar />
      <section
        style={{
          backgroundImage: `url(${hero}), url(${botomHero})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top, bottom",
        }}
      >
        <Header />
        {showModal ? popUp() : null}
        <NewListing />
        <QuickArticles />
        <FaqCarousel />
        <TeamMember />
      </section>
      <MailingRegistration />
      <Footer />
    </div>
  );
}

function ModalPopUp() {
  return (
    <Popup open={true} modal>
      <div
        className="container bg-white rounded shadow-lg p-5 text-center"
        style={{
          backgroundImage: `url(${botomHero})`,
          backgroundSize: "100%",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1 style={{ fontFamily: "Montserrat", color: "#4AB29D" }}>
          <span
            style={{
              color: "#2276C9",
              fontFamily: "Montserrat",
              fontWeight: "bold",
            }}
          >
            ICO
          </span>{" "}
          Coming Soon
        </h1>
        <img
          className=""
          src={logoPopup}
          style={{ height: "20vh", marginTop: "5%" }}
          alt=""
        />
        <div className="mx-auto pt-5">
          <button
            type="button"
            style={{ backgroundColor: "#4AB29D" }}
            className="btn-primary mx-auto custom-btn"
            onClick={() => {
              window.open("https://ico.proprt.io");
            }}
          >
            See Details
          </button>
        </div>
      </div>
    </Popup>
  );
}

// {/* <Popup
//         open={true}
//         modal
//         onClose={() => {
//           setShowModal(false);
//         }}
//       >
//         <form
//           className="container rounded shadow-lg p-5 pb-3 text-center"
//           style={{
//             backgroundSize: "100%",
//             backgroundPosition: "bottom",
//             backgroundRepeat: "no-repeat",
//             backgroundColor: "#4E81A2",
//             border: "20px solid white",
//           }}
//           onSubmit={async (e) => {
//             e.preventDefault();
//             if (data.email === "" || data.name === "" || data.value === "") {
//               alert("Please fill everything.");
//             } else {
//               await fetch(
//                 "https://assets.mailerlite.com/jsonp/1318/forms/55423493200676580/subscribe",
//                 {
//                   method: "POST",
//                   body: JSON.stringify({
//                     fields: {
//                       email: data.email,
//                       name: data.name,
//                       total: data.value,
//                     },
//                   }),
//                   headers: {
//                     // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
//                     "Content-Type": "application/json",
//                   },
//                 }
//               ).then((res) => res.json());
//               alert("Thank you for your interest. We will contact you soon.");
//             }
//           }}
//         >
//           <div
//             style={{
//               width: "50vw",
//               paddingBottom: "10vh",
//               position: "relative",
//             }}
//           >
//             <h1
//               style={{
//                 fontFamily: "Montserrat",
//                 color: "white",
//                 fontWeight: "bold",
//                 position: "absolute",
//                 left: 0,
//               }}
//             >
//               Receive Airdrops!
//             </h1>
//             {!isTabletOrMobile && (
//               <img
//                 className=""
//                 src={logoPopup}
//                 style={{
//                   height: "20vh",
//                   right: 0,
//                   marginTop: "-3.7vh",
//                   top: 0,
//                   position: "absolute",
//                 }}
//                 alt=""
//               />
//             )}
//           </div>
//           <p
//             style={{
//               fontFamily: "Montserrat",
//               color: "white",
//               textAlign: "start",
//             }}
//           >
//             Note sure what they are? We will guide you..
//           </p>
//           <div>
//             <input
//               className="login-input"
//               type="email"
//               placeholder="Email"
//               style={{
//                 borderColor: "white",
//                 width: "30%",
//                 borderBlockStart: "none",
//                 border: "none",
//                 boxSizing: "border-box",
//                 height: "40px",
//                 padding: "10px",
//                 backgroundColor: "white",
//                 borderRadius: 0,
//               }}
//               onChange={(e) => {
//                 setData({ ...data, email: e.target.value });
//               }}
//             />
//             <button
//               type="submit"
//               style={{
//                 backgroundColor: "#FFFC6E",
//                 maxWidth: "10rem",
//                 borderRadius: 0,
//                 paddingLeft: "10px",
//                 paddingRight: "10px",
//                 color: "#000000",
//               }}
//               className="btn-primary mx-auto custom-btn mb-5 mt-4"
//             >
//               SIGN ME UP!
//             </button>
//             {/* <div className="mx-auto"></div> */}
//           </div>
//           <p style={{ textAlign: "start", color: "white" }}>
//             We will never spam you or share your details. Unsubscribe anytime.
//           </p>
//           <a href="https://ico.proprt.io/">
//             <p style={{ textAlign: "start", color: "white" }}>
//               For details on our{" "}
//               <span style={{ color: "#FFFC6E", fontWeight: "bold" }}>
//                 ICO coming soon
//               </span>{" "}
//               click here
//             </p>
//           </a>
//         </form>
//       </Popup> */}
