import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBMocFs0BZ0nkSJldKr2sZ9qU4rCMIZJxw",
  authDomain: "proprt-io.firebaseapp.com",
  projectId: "proprt-io",
  storageBucket: "proprt-io.appspot.com",
  messagingSenderId: "540618020722",
  appId: "1:540618020722:web:0959f3ccbf251278f7efeb",
  measurementId: "G-6G302YWELK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (
  firstName,
  lastName,
  email,
  password,
  mobileNumber
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      type: "user",
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      email,
      authProvider: "local",
    });

    // Send Email Verification
    await sendEmailVerification(user);
    alert("Email sent");
    auth.signOut();
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerAsAgent = async (
  firstName,
  lastName,
  email,
  password,
  mobileNumber,
  agencyName,
  licenceNumber
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      type: "agent",
      firstName: firstName,
      lastName: lastName,
      mobileNumber: mobileNumber,
      email,
      agencyName,
      licenceNumber,
      authProvider: "local",
    });

    // Send Email Verification
    await sendEmailVerification(user);
    alert("Email sent");
    auth.signOut();
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  registerAsAgent,
};
