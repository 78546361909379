import React, { useState, useEffect } from "react";
import Axios from "axios";

export default function DashboardPage() {
  const bearerToken =
    "Bearer bfdd38edd3984101316c6b4fd805594dd4146b2e022862f415aeefe17a7e2c5a5a4d687adca1704f1a406a33dbfdd8bdb1b6721ed67f847ee845c795c4a2d0fbb392d7538714882f957d7aa68f3b702d2674c8398632e7d6737647278c1dbba0bec20ad65f40347da459bb45d9a797a2cbc8397707d59b398449a59013b99fa4";

  const url = "https://api1.proprt.io/api/auth/local/";

  const userId = "14";

  const checkUserUrl = `https://api1.proprt.io/api/users/${userId}?populate[wallet][fields]=publicAddress`;

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    balance: null,
    address: null,
  });

  const checkBallanceUrl = `https://api.etherscan.io/api?module=account&action=balance&address=${user.address}&tag=latest&apikey=NCJSF75SIRZA1S3A2DY18MHB8HN6SMSUJT`;

  const onApprove = async (data, actions) => {
    await Axios.post(
      url,
      {
        identifier: user.identifier,
        password: user.password,
      },
      {
        headers: {
          Authorization: bearerToken,
        },
      }
    )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          window.alert("You have successfully Logged in");
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("There was an error. Please try again.");
      });
  };

  const checkBalance = async () => {
    console.log("checkBalance");
    setLoading(true);
    try {
      await Axios.get(checkBallanceUrl)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setUser({ ...user, balance: res.data.result / 10 ** 18 });
            console.log("balance in ether", res.data.result / 10 ** 18);
          }
        })
        .catch((err) => {
          console.log("error \n", err);
        });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      await Axios.get(checkUserUrl, {
        headers: {
          Authorization: bearerToken,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setUser({ ...user, address: res.data.wallet.publicAddress });
          }
        })
        .catch((err) => {
          console.log("error \n", err);
        });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(async () => {
    await fetchData();
    // await checkBalance();
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen bootstrap-off">
        <div className="my-auto mx-auto">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bootstrap-off">
      <div className="my-auto mx-auto">
        <form className="w-full max-w-sm mb-10 transition-all duration-300 ease-in-out">
          <div
            className={`flex items-center border-b mt-6 py-2 border-teal-500`}
          >
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="address"
              placeholder="address"
              aria-label="address"
              value={user.address ? user.address : ""}
              onChange={(text) => {
                setUser({ ...user, address: text.target.value });
              }}
            />
          </div>
          <div
            className={`flex items-center border-b mt-6 py-2 border-teal-500`}
          >
            <input
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
              type="balance"
              placeholder="balance"
              aria-label="balance"
              disabled
              value={user.balance ? user.balance : ""}
            />
          </div>
        </form>
        <button
          className={`flex-shrink-0 bg-teal-500 text-white hover:bg-slate-500 border-teal-500 text-sm  py-1 px-2 rounded transition-all duration-300 ease-in-out`}
          type="button"
          onClick={async (e) => {
            e.preventDefault();
            await checkBalance();
          }}
        >
          Check
        </button>
      </div>
    </div>
  );
}
