import React from "react";

export default function Tenants() {
  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <h2 className="text-4xl title-font text-gray-500 tracking-widest text-center">
          Initial Sellers
        </h2>
        <p className="text-xl text-gray-500 text-center mt-2">
          Lets use an $800,000 property as an example to compare our system
          versus the current system
        </p>
        <div className="lg:w-4/5 mx-auto flex flex-wrap mt-4">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            {/* <h2 className="text-sm title-font text-gray-500 tracking-widest">
              BRAND NAME
            </h2> */}
            <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
              Current System
            </h1>
            <div className="flex border-t border-b border-gray-200 py-2">
              <span className="text-gray-500">Sales Price</span>
              <span className="ml-auto text-gray-900">$800,000</span>
            </div>
            <p className="text-gray-900 text-lg title-font font-medium mb-0 mt-4">
              Expenses
            </p>
            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Agent Advertising</span>
              <span className="ml-auto text-gray-900">$3,000</span>
            </div>
            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Seller's Agents Fees (2.5%)</span>
              <span className="ml-auto text-gray-900">$20,000</span>
            </div>
            <div className="flex border-t border-b mb-6 border-gray-200 py-2">
              <span className="text-gray-500">Legal</span>
              <span className="ml-auto text-gray-900">$1,000</span>
            </div>
            <div className="flex">
              <span className="title-font font-medium text-2xl text-gray-900">
                Seller Receives
              </span>
              <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                $776,000
              </span>
            </div>
          </div>
          {/* right */}
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
              PROPRT.io
            </h1>
            <div className="flex border-t border-b border-gray-200 py-2">
              <span className="text-gray-500">Sales Price</span>
              <span className="ml-auto text-gray-900">$800,000</span>
            </div>
            <p className="text-gray-900 text-lg title-font font-medium mb-0 mt-4">
              Expenses
            </p>
            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Agent Advertising</span>
              <span className="ml-auto text-gray-900">$0</span>
            </div>
            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Seller's Agents Fees (2.5%)</span>
              <span className="ml-auto text-gray-900">$0</span>
            </div>
            <div className="flex border-t border-b mb-6 border-gray-200 py-2">
              <span className="text-gray-500">Legal</span>
              <span className="ml-auto text-gray-900">$0</span>
            </div>
            <div className="flex">
              <span className="title-font font-medium text-2xl text-gray-900">
                Seller Receives
              </span>
              <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                $800,000
              </span>
            </div>
            <div className="flex mt-3 text-[#2276C9]">
              <span className="title-font font-medium text-xl">PLUS BONUS</span>
              <span className="flex ml-auto title-font font-medium text-xl">
                <span className="fw-bold">REST</span>&nbsp;5,000
              </span>
            </div>
          </div>
          <p className="mt-10 space-y-1">
            Seems too good to be true? This is{" "}
            <span className="fw-bold">HOW</span> the platform works.
            <ol className="list-decimal ml-7 space-y-1">
              <li>Initial Minting Fees are paid by buyers</li>
              <li>As buyers are purchasing smaller amounts, fees are small</li>
              <li>
                Seller's Agents Fees reduced significantly due to efficiences of
                platform
              </li>
              <li>Agents act as BUYERS agents - hence fees paid by buyers</li>
              <li>
                Legal and platform fees all for buyers benefit, so they pay
                these fees.
              </li>
              <li>Secondary Sellers pay 1.5% Sellers Fee</li>
            </ol>
          </p>
        </div>
      </div>

      {/* 2nd */}
      <div className="container px-5 py-24 mx-auto">
        <h2 className="text-4xl title-font text-gray-500 tracking-widest text-center">
          Ongoing Sellers
        </h2>
        <p className="text-xl text-gray-500 text-center mt-2">
          From a <span className="fw-bold">ONGOING SELLERS</span> perspective:
        </p>
        <div className="lg:w-4/5 mx-auto flex flex-wrap mt-4">
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            {/* <h2 className="text-sm title-font text-gray-500 tracking-widest">
              BRAND NAME
            </h2> */}
            <h1 className="text-gray-900 text-3xl title-font fw-bold font-medium mb-4">
              Current System
            </h1>

            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Agent Advertising</span>
              <span className="ml-auto text-gray-900">0.38%</span>
            </div>
            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Seller's Agents Fees</span>
              <span className="ml-auto text-gray-900">2.50%</span>
            </div>
            <div className="flex border-t border-b mb-6 border-gray-200 py-2">
              <span className="text-gray-500">Legal</span>
              <span className="ml-auto text-gray-900">0.15%</span>
            </div>
            <div className="flex">
              <span className="title-font font-medium text-2xl text-gray-900">
                Total Fees
              </span>
              <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                3.03%
              </span>
            </div>
          </div>
          {/* right */}
          <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
            <h1 className="text-3xl title-font fw-bold font-medium mb-4 text-[#2276C9]">
              PROPRT.io
            </h1>
            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Agent</span>
              <span className="ml-auto text-gray-900">0.00%</span>
            </div>
            <div className="flex border-t border-gray-200 py-2">
              <span className="text-gray-500">Listing / Network Fee</span>
              <span className="ml-auto text-gray-900">2.50%</span>
            </div>
            <div className="flex border-t border-b mb-6 border-gray-200 py-2">
              <span className="text-gray-500">Legal</span>
              <span className="ml-auto text-gray-900">0.00%</span>
            </div>
            <div className="flex">
              <span className="title-font font-medium text-2xl text-gray-900">
                Total Fees
              </span>
              <span className="flex ml-auto title-font font-medium text-2xl text-gray-900">
                1.50%
              </span>
            </div>
          </div>
          <p className="mt-10 space-y-1">
            Differences from Initial Sellers:
            <ol className="list-decimal ml-7 space-y-1">
              <li>Only pay Network fee</li>
              <li>Half fee from traditional sale</li>
            </ol>
          </p>
        </div>
      </div>
    </section>
  );
}
