import React, { useState } from "react";

export default function MailingRegistration() {
  var [email, setEmail] = useState();

  const maQiy =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiZTZlZDZkYjY4YjFjM2I0NTFiOTQ1ZjYzYjczNmEyMTRjNWQ5ZDViMGJmYWUwNzdhMTcyY2Y5MTRmYWM0ZDZjMDQzZTVjMTRlMWFlMjFmY2EiLCJpYXQiOjE2NDgwMDE4NzMuMTUzNzMxLCJuYmYiOjE2NDgwMDE4NzMuMTUzNzM0LCJleHAiOjQ4MDM2NzU0NzMuMTUwNDE1LCJzdWIiOiIxODY4Iiwic2NvcGVzIjpbXX0.IFsGrQMJipv1X6Ld_3j26_sQ1CzAaZeR1LUhpr53XU-lhgg4lpLXf85W5whxgRCvCG-6v0QulsI4K0vW5xBnR3j1ILBY6JdQI2WyiT0mibS3c0Z_7PY2QJCcJsAHT-Uike0KvSA9hG1oGOCEW5Y8p-JR6TlzNwMUl6IDGYDV6deGyI1nsYiTYXpoL1ES077AEz3KDPpOlLgjf0fRhJ842LUzgJUrTbGt3tJ5VjOeb6fBVCYuE9etafgTTCIsOaRvO61PF1uV2h8jUs1lsZJQK_G2pxv1_fZoLdfr5IKj7dcByGFLA1KpyWB4FDAWJmR30I8GbkEuigFMzAyG4AqE4HSKOalPXd0WhiGQs5YIhbjVHvsRpYzp2kyTCjgadJUYnc0w3ddgStmf6vls5LM5e5R3fJwpVXNQVUTkqthlcqXglNjZmXwzH2pGCM_oGfkleSsUiQOUf_ZSYiCIhpp1sg76ifR9zcO7YG0EsgxAxMrTYw9hWHgEp4GANP-bY2iugNIwpjFgqZdGBceMVIVUgSafsA0dk5iLPtCY47UdJ2gcTTXvVzMAP52jRGju-guzKbovxw6IIjii7JBAHTIROXDJAfbzSnacWewrpf1TY55K1dNuof97tBD--yXxZjFMMg8rzVjp0d_cfIRHAfNgqzFW6unlZnjhK6j9wy0cJ78";

  return (
    <div
      id="mailingRegistration"
      style={{
        backgroundColor: "#3489DB",
        paddingTop: "3.5rem",
        paddingBottom: "3.5rem",
        alignItems: "center",
      }}
    >
      <div className="container row text-center mx-auto">
        <div className="col-md-6 bootstrap-off" style={{}}>
          <p style={{ fontSize: "20px", color: "white", marginTop: "-0.7rem" }}>
            Join our mailing list to receive updates <br />
            on new listings and special offers.
          </p>
        </div>
        <div className="col-md-6 bootstrap-off" style={{}}>
          <form
            className="d-flex ms-3 bootstrap-off validate"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            onSubmit={async (e) => {
              e.preventDefault();
              await fetch(
                "https://assets.mailerlite.com/jsonp/1318/forms/51049405316334751/subscribe",
                {
                  method: "POST",
                  body: JSON.stringify({
                    fields: {
                      email: email,
                      // name: "TEST18MAY",
                    },
                  }),
                  headers: {
                    // Authorization: "c0f8c0d4f6c4f6f0f6f8f8f0f4f4f0f0",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((res) => res.json())
                .then((data) => {
                  console.log(data);
                  if (data.success === false) {
                    console.log("error");
                    console.log(email);
                  } else {
                    console.log("success");
                    console.log(email);
                  }
                });
              alert(
                "Thank you. Confirmation email has been sent to you, please check your email."
              );
            }}
          >
            <input
              className="form-control my-auto"
              id="mce-EMAIL"
              name="EMAIL"
              placeholder="Enter email address"
              aria-label="Email"
              style={{
                // width: '90%',
                height: "40px",
                borderRadius: "9px",
                border: "none",
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button
              className="btn btn-success custom-btn my-auto"
              type="submit"
              style={{
                backgroundColor: "#4AB29D",
                width: "25%",
                height: "40px",
                border: "none",
                borderRadius: "9px",
                marginLeft: "10px",
              }}
            >
              Sign up
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
