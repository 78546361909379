import React from "react";
import logo from "../img/logo/proprt_logo_final.png";

import xMark from "../img/icons/xmarkv2_red.png";
import checkMark from "../img/icons/check_markv2.png";

import illustration_nfpt from "../img/illustration/nfpt.png";
import illustration_rest from "../img/illustration/rest_ token 050522.png";
import illustration_proprt_token from "../img/illustration/proprt_ token.png";
import { useMediaQuery } from "react-responsive";

export default function QuickArticles() {
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  return (
    <section style={{}} className="mt-5 pt-5">
      <div className="container text-center">
        <h1
          style={{
            color: "#2276C9",
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
          className="text-3xl"
        >
          Two ways to invest
        </h1>
        <p
          className="text-center mt-4"
          style={{ marginBottom: "0rem", color: "grey" }}
        >
          You can either own a unique piece of property (PROPRT) secured on the
          blockchain
          <br /> or you can own a piece of the whole ecosystem through REST
          tokens.
        </p>
      </div>
      <div className="row text-center mt-5 ms-5 me-5 align-content-center justify-content-center">
        <div className="col-md-4">
          <img
            className=" headerIcons  mx-auto"
            src={logo}
            alt="logo"
            onClick={() => {
              alert("Coming soon");
            }}
          />
          <h3 className="mt-5">PROPRT</h3>
          <p style={{ color: "grey" }}>
            A PROPRT is a Non-Fungible Property Token, which means each token is
            unique. This is an evolution of the NFT, which is built on the
            Ethereum blockchain (ERC-1155). You can verify your ownership,
            secure it in your own wallet or even take it offline (cold storage).
          </p>
        </div>
        <div className="col-1"></div>
        <div className="col-md-4">
          <img
            className=" headerIcons  mx-auto"
            src={illustration_rest}
            alt="logo"
            onClick={() => {
              alert("Coming soon");
            }}
          />
          <h3 className="mt-5">REST</h3>
          <p style={{ color: "grey" }}>
            REST tokens are standard ERC-20 tokens used to build and run the
            PROPRT.io platform. The tokens represent an ownership stake in the
            value of the entire ecosystem. Fees generated go to REST token
            holders. For more information refer to the{" "}
            <a
              href="https://proprt.io/PROPRT.io-Tokenomics-v0.4.pdf"
              style={{ color: "#2276C9" }}
            >
              tokenomics document
            </a>
          </p>
        </div>
      </div>

      <hr
        className="line-section mx-auto"
        style={{ marginTop: "7%", marginBottom: "7%" }}
      />

      <section id="comparison-chart">
        <div className="text-center">
          <h1
            style={{
              color: "#2276C9",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
            className="text-3xl"
          >
            Comparison Chart
          </h1>
        </div>
        <div className="container text-center mx-auto row pt-5 p-5">
          <div
            className="bg-light p-5 shadow-lg mx-auto"
            id="landing-comparison-table"
          >
            <div
              className="row mx-auto"
              id="comparison-table-header"
              style={{ marginBottom: "-1rem" }}
            >
              <div className="col-3 col-md-5 text-start mx-auto p-0">
                <p
                  id="feature-landing-title"
                  className="text-start"
                  style={{ fontSize: isMobile ? "10px" : "15px" }}
                >
                  FEATURES
                </p>
              </div>
              <div className="col-2 content-center mx-auto text-start">
                <p
                  style={{
                    fontSize: isMobile ? "8px" : "15px",
                  }}
                >
                  Traditional Ownership
                </p>
              </div>
              <div className="col-2 content-center text-center mx-auto">
                <p
                  className="mx-md-auto mx-1"
                  style={{ fontSize: isMobile ? "8px" : "15px" }}
                >
                  REIT
                </p>
              </div>
              <div className="col-2 content-center mx-auto">
                <p
                  className="mx-auto"
                  style={{ fontSize: isMobile ? "8px" : "15px" }}
                >
                  PROPRT Platform
                </p>
              </div>
            </div>
            {comparisonRowList()}
          </div>
        </div>

        <div className="justify-content-center align-items-center text-center">
          <button
            type="button"
            className="headerButtonBlue"
            onClick={() => {
              window.open("https://proprt.io/comparison", "_blank");
            }}
          >
            View Full Page
          </button>
        </div>
      </section>

      <hr className="line-section mx-auto" style={{ marginTop: "7%" }} />

      <div
        className="container text-center mt-5 pt-5 pb-5"
        style={{ width: "55vw" }}
      >
        <h1
          style={{
            color: "#2276C9",
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
          className="text-3xl"
        >
          Building a Full Ecosystem
        </h1>
        <p
          className="text-center mt-4"
          style={{ marginBottom: "0rem", color: "grey" }}
        >
          Bringin Liquidity to Property Investors.
        </p>

        <p className="mt-5" style={{ marginBottom: "0rem", color: "grey" }}>
          Users benefit from holding tokens in individual properties (PROPRT),
          or in the entire ecosystem (REST).
          <br />
          <br />
          The platform will grow into a DeFi (Decentralised Finance) platform
          where token holders can borrow, lend, stake and provide liquidity to
          generate additional returns. Once the platform reaches maturity,
          PROPRT holders will be able to vote on the direction of the platform
          based on DAO (Decentralised Autonomous Organisation) governance. Tight
          restrictions and frameworks will ensure compliance with legal and
          regulatory obligations.
          <br />
          <br />
          Money was once backed by Gold, a tangible, finite resource. Real
          Estate (REST) is a cryptocurrency backed by Property (PROPRT), a
          tangible, finite resource with income.
        </p>
        <button
          type="button"
          className="headerButtonBlue mt-5"
          onClick={() => {
            window.open("https://proprt.io/faq", "_blank");
          }}
        >
          How it works
        </button>
      </div>

      <hr className="line-section mx-auto" style={{ marginTop: "7%" }} />

      <div
        className="container text-center mt-5 pt-5 pb-5"
        style={{ width: "55vw" }}
      >
        <p
          className="text-center"
          style={{ marginBottom: "0rem", color: "grey" }}
        >
          As seen on:
        </p>
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 mx-auto">
          <div className="flex flex-wrap -m-4  justify-center space-x-10">
            {/* <BlogCard
              title="Buy a piece of a Gold Coast property from $220"
              category="REALESTATE.COM.AU"
              description="A Gold Coast father-of-four who lost his job during Covid has come up with a quirky way to get onto the property ladder."
              image="https://www.realestate.com.au/news/wp-content/uploads/2022/09/capi_9e2f7c60ef0400526eda04299fb09543_d261159d3ba13fc80472a7d13c521cfe.jpeg"
              link="https://www.dailytelegraph.com.au/real-estate/queensland/gold-coast/buy-a-piece-of-a-luxury-gold-coast-property-from-220/news-story/9e2f7c60ef0400526eda04299fb09543?rsf=syn:news:nca:dt:spa"
            />
            <BlogCard
              title="Buy a piece of a Gold Coast property from $220"
              category="DAILYTELEGRAPH.COM.AU"
              description="A Gold Coast father-of-four who lost his job during Covid has come up with a quirky way to get onto the property ladder."
              image="https://www.realestate.com.au/news/wp-content/uploads/2022/09/capi_9e2f7c60ef0400526eda04299fb09543_d261159d3ba13fc80472a7d13c521cfe.jpeg"
              link="https://www.dailytelegraph.com.au/real-estate/queensland/gold-coast/buy-a-piece-of-a-luxury-gold-coast-property-from-220/news-story/9e2f7c60ef0400526eda04299fb09543?rsf=syn:news:nca:dt:spa"
            />
            <BlogCard
              title="In the last 10 years, house prices have grown 3 times faster than wages!"
              category="CATEGORY"
              description="A Gold Coast father-of-four who lost his job during Covid has come up with a quirky way to get onto the property ladder."
              image="https://miro.medium.com/max/640/1*umbu_WKaCZKScqyYCgCgVg.png"
              link="https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4"
            /> */}

            <img
              className="h-12 border-opacity-60 rounded-lg overflow-hidden hover:cursor-pointer hover:scale-110 transform transition duration-500 ease-in-out"
              src={
                "https://s1.rui.au.reastatic.net/rui-static/img/rea-logo-v4.png"
              }
              alt="blog"
              onClick={() => {
                window.open(
                  "https://www.realestate.com.au/news/buy-a-piece-of-a-luxury-gold-coast-property-from-220/",
                  "_blank"
                );
              }}
            />
            <img
              className="h-12 border-opacity-60 rounded-lg overflow-hidden hover:cursor-pointer hover:scale-110 transform transition duration-500 ease-in-out"
              src={
                "https://miro.medium.com/max/8978/1*s986xIGqhfsN8U--09_AdA.png"
              }
              alt="blog"
              onClick={() => {
                window.open(
                  "https://medium.com/coinmonks/step-onto-the-property-ladder-29aa2d803cc4",
                  "_blank"
                );
              }}
            />
            <img
              className="h-12 border-opacity-60 rounded-lg overflow-hidden hover:cursor-pointer hover:scale-110 transform transition duration-500 ease-in-out"
              src={
                "https://upload.wikimedia.org/wikipedia/commons/e/e7/Daily_Telegraph.svg"
              }
              alt="blog"
              onClick={() => {
                window.open(
                  "https://www.dailytelegraph.com.au/real-estate/queensland/gold-coast/buy-a-piece-of-a-luxury-gold-coast-property-from-220/news-story/9e2f7c60ef0400526eda04299fb09543?rsf=syn:news:nca:dt:spa",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
      </section>
    </section>
  );
}

const BlogCard = ({ title, description, image, category, link }) => {
  return (
    <div className="p-4 md:w-1/3">
      <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
        <img
          className="lg:h-48 md:h-36 w-full object-cover object-center"
          src={image}
          alt="blog"
        />
        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
            {category}
          </h2>
          <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
            {title}
          </h1>
          <p className="leading-relaxed mb-3">{description}</p>
          <div className="flex items-center flex-wrap ">
            <a
              className="text-[#2276C9] inline-flex items-center md:mb-2 lg:mb-0"
              href={link}
            >
              Learn More
              <svg
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14" />
                <path d="M12 5l7 7-7 7" />
              </svg>
            </a>
            <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
              <svg
                className="w-4 h-4 mr-1"
                stroke="currentColor"
                strokeWidth={2}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                <circle cx={12} cy={12} r={3} />
              </svg>
              1.2K
            </span>
            <span className="text-gray-400 inline-flex items-center leading-none text-sm">
              <svg
                className="w-4 h-4 mr-1"
                stroke="currentColor"
                strokeWidth={2}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z" />
              </svg>
              6
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

var comparisonRowList = () => {
  var dataList = [];
  dataList.push(["Blockchain", null]);
  dataList.push(["Low fees", false, false, true]);
  dataList.push(["Funds immediately accessible", false, false, true]);
  dataList.push(["Buy with crypto", false, false, true]);
  dataList.push(["Blockchain secured", false, false, true]);
  dataList.push(["Low exit fees", false, false, true]);
  dataList.push(["Reduced third party risk", false, false, true]);

  dataList.push(["Fractional Property", null]);
  dataList.push(["Low capital required", false, true, true]);
  dataList.push(["Hassle- free", false, true, true]);
  dataList.push(["Liquidity (easy to sell)", false, true, true]);
  dataList.push(["No mortgage required", false, true, true]);
  dataList.push(["Build portfolio incrementally", false, true, true]);
  dataList.push(["Less risk*", false, true, true]);
  dataList.push(["Could live in property", false, true, true]);

  dataList.push(["Property", null]);
  dataList.push(["Transparency", true, false, true]);
  dataList.push(["Control property selection", true, false, true]);
  dataList.push(["Residential property focus", true, false, true]);
  dataList.push(["Control of sale price", true, false, true]);
  dataList.push(["Ability to learn property investing", true, false, true]);

  dataList.push(["DeFi", null]);
  dataList.push(["Financial derivatives available (DeFi)", false, false, true]);
  dataList.push(["Store off-line", false, false, true]);
  dataList.push(["Extra income & capital gains", false, false, true]);
  dataList.push(["Offline storage of asset", false, false, true]);
  dataList.push(["Lend, borrow and control asset", false, false, true]);

  var returnValue = [];
  for (var i = 0; i < dataList.length; i++) {
    var feature = dataList[i][0];
    var traditionalOwnership = dataList[i][1];
    var reit = dataList[i][2];
    var proprtio = dataList[i][3];

    // comparisonTableRow(feature, traditionalOwnership, reit, proprtio);
    returnValue.push(
      comparisonTableRow(feature, traditionalOwnership, reit, proprtio)
    );
  }

  return returnValue;
};

function comparisonTableRow(feature, traditionalOwnership, reit, proprtio) {
  if (traditionalOwnership !== null) {
    return (
      <div className="row mb-3 mx-auto  content-center">
        <div className="col-4 col-md-5 text-start mx-auto align-middle align-items-center">
          <p className="fw-300 align-middle my-auto">
            {feature !== "Financial derivatives available (DeFi)"
              ? feature.toUpperCase()
              : "FINANCIAL DERIVATIVES AVAILABLE (DeFi)"}
          </p>
        </div>
        <div className="col-2 mx-auto">
          <img
            alt=""
            src={traditionalOwnership ? checkMark : xMark}
            className="comparison-icons"
          />
        </div>
        <div className="col-2 mx-auto">
          <img
            alt=""
            src={reit ? checkMark : xMark}
            className="comparison-icons"
          />
        </div>
        <div className="col-2 mx-auto">
          <img
            alt=""
            src={proprtio ? checkMark : xMark}
            className="comparison-icons"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="row mb-3 mt-4">
        {/* <div className="col-6 text-start">
          <p style={{ fontSize: 19 }}>&nbsp;</p>
        </div> */}
        <div className="col-6 text-start">
          <p
            className="fw-300 align-middle my-auto"
            style={{ color: "#2276c9" }}
          >
            {feature}
          </p>
        </div>
      </div>
    );
  }
}
