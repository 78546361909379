import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";

import illustration_nfpt from "../../img/logo/proprt_logo_final.png";
import illustration_rest from "../../img/illustration/rest_ token 050522.png";
import hero from "../../img/LandingPage/hero02b_60grad.jpg";
import botomHero from "../../img/Marketplace/houses_bg02.jpg";
import {
  useEtherBalance,
  useEthers,
  useSendTransaction,
  useToken,
} from "@usedapp/core";
import { useMediaQuery } from "react-responsive";

import { formatEther } from "@ethersproject/units";
import { utils } from "ethers";
import { useCoingeckoTokenPrice } from "@usedapp/coingecko";

import Footer from "../../components/Footer";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db, logout } from "../../components/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

export default function OverallPortfoilo() {
  const { activateBrowserWallet, account } = useEthers();
  const etherBalance = useEtherBalance(account);
  const { sendTransaction, state } = useSendTransaction();
  var address;
  var amount;
  var newDaiAddress;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const transactionTest = () => {
    // setDisabled(true)
    sendTransaction({
      to: `${address}`,
      value: utils.parseEther(`${amount}`),
    });
    // console.log(address);
  };

  var DAI_ADDRESS = "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce";
  var [daiAddress, setDaiAddress] = useState(DAI_ADDRESS);
  const daiInfo = useToken(daiAddress);

  const wethPrice = useCoingeckoTokenPrice(daiAddress, "aud");

  const [user, loading, error] = useAuthState(auth);
  const [accountDetails, setAccountDetails] = useState({});
  const navigate = useNavigate();

  // const { state } = useLocation();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      if (data.type === "user") {
        setAccountDetails({
          name: data.firstName + " " + data.lastName,
          email: data.email,
          type: data.type,
        });
      } else {
        setAccountDetails({
          name: data.firstName + " " + data.lastName,
          email: data.email,
          type: data.type,
          agency: data.agencyName,
        });
      }

      // setName(data.firstName + " " + data.lastName);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    // if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);

  return (
    <>
      <Navbar />
      <section
        id="Overallortfolio"
        style={{
          paddingTop: "5rem",
          minHeight: "100rem",
          backgroundImage: `url(${hero}), url(${botomHero})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top, bottom",
        }}
      >
        <div className="row p-5">
          <div className="col-sm-2">
            <p>Overall Portfolio</p>
            <p>Property List</p>
            <p>Account Settings</p>
            <p>Account Details</p>
            <p>Help Center</p>
          </div>
          <div
            className="col bootstrap-off"
            style={isTabletOrMobile ? null : { marginRight: "6%" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              Welcome {accountDetails.name}{" "}
              {accountDetails.type === "agent" && (
                <>from {accountDetails.agency}</>
              )}
            </h1>
            <p>{user?.email}</p>
            <div
              style={{
                backgroundColor: "white",
                padding: "1rem",
                marginBottom: "1rem",
              }}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  color: "#48B29D",
                }}
              >
                This will be{" "}
                <span style={{ fontWeight: "bolder", color: "#2276C9" }}>
                  {accountDetails.type} dashboard
                </span>{" "}
                screen.
                <br />
                Still in development process.
                <br />
                <span style={{ color: "#2276C9" }}>Comeback later.</span>{" "}
              </h1>
              <button onClick={logout} className="login-input">
                Logout
              </button>
            </div>
            <div
              style={{
                marginBottom: "2%",
                borderRadius: "10px",
                "-webkit-box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
                "box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
              }}
            >
              <div
                className="bootstrap-off"
                style={{
                  backgroundColor: "#4AB29D",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "0.5rem",
                }}
              >
                <p className="bootstrap-off text-white fw-bold justify-content-around align-content-around">
                  PROPERTY HOLDINGS
                </p>
              </div>
              <div
                className="row bootstrap-off pb-2 pt-2"
                style={{
                  backgroundColor: "white",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                <div
                  className="col-md-2 bootstrap-off p-5"
                  style={{ backgroundColor: "" }}
                >
                  <img
                    alt="PROPRT Logo"
                    src={illustration_nfpt}
                    className="mx-auto d-block"
                    style={{
                      width: "100%",
                      minWidth: "3rem",
                      maxWidth: "13rem",
                    }}
                  />
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    REST
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    REST #1
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    REST #2
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    ID
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    ID001
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    ID002
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Number
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    45
                  </h5>
                  {etherBalance && (
                    <h5 style={{ fontWeight: "400", color: "grey" }}>
                      {formatEther(etherBalance)}
                    </h5>
                  )}
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto "
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Value
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $1,300
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $5,600
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    $6,900
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Growth
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    5%
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    3%
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center p-0 pt-5 pb-5 mx-auto"
                  style={{
                    padding: 0,
                    backgroundColor: "",
                    // 'borderRadius': '10px',
                    // border: '1px solid #4AB29D',
                    // marginLeft: '1%',
                    // marginBottom: '1%',
                  }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Income
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $65.00
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $280.00
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    $345.00
                  </h5>
                </div>

                <div
                  className="hider col-2 bootstrap-off text-center justify-content-center align-content-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <br />
                  <h5 className="row">
                    <div className="col-5">
                      <button
                        className="portfolioButtonCyan"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Buy
                      </button>
                    </div>
                    <div className="col-5 ">
                      <button
                        className="portfolioButtonBlue"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Sell
                      </button>
                    </div>
                  </h5>
                  <h5 className="row">
                    <div className="col-5 padding-0">
                      <button
                        className="portfolioButtonCyan"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Buy
                      </button>
                    </div>
                    <div className="col-5 padding-0">
                      <button
                        className="portfolioButtonBlue"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Sell
                      </button>
                    </div>
                  </h5>
                  <br />
                  <h5 className="row">
                    <div className="col-10 padding-0">
                      <button
                        className="portfolioButtonGrey"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        Buy other
                      </button>
                    </div>
                  </h5>
                </div>
              </div>
            </div>
            <div
              style={{
                marginBottom: "2%",
                borderRadius: "10px",
                "-webkit-box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
                "box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
              }}
            >
              <div
                className="bootstrap-off"
                style={{
                  backgroundColor: "#4AB29D",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "0.5rem",
                }}
              >
                <p className="bootstrap-off text-white fw-bold justify-content-around align-content-around">
                  REST HOLDINGS (Address: {account})
                </p>
              </div>
              <div
                className="row bootstrap-off pb-2 pt-2"
                style={{
                  backgroundColor: "white",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                <div
                  className="col-md-2 bootstrap-off p-5"
                  style={{ backgroundColor: "" }}
                >
                  <img
                    alt="PROPRT Logo"
                    src={illustration_rest}
                    className="mx-auto d-block"
                    style={{
                      width: "100%",
                      minWidth: "3rem",
                      maxWidth: "13rem",
                    }}
                  />
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Token
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    REST
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    ETH
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Type
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    Locked
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    Unclocked
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Number
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    45
                  </h5>
                  {etherBalance && (
                    <h5 style={{ fontWeight: "400", color: "grey" }}>
                      {formatEther(etherBalance)}
                    </h5>
                  )}
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Value
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $1,300
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $5,600
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    $6,900
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Growth
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    5%
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    3%
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center p-0 pt-5 pb-5 mx-auto"
                  style={{
                    padding: 0,
                    backgroundColor: "",
                    // 'borderRadius': '10px',
                    // border: '1px solid #4AB29D',
                    // marginLeft: '1%',
                    // marginBottom: '1%',
                  }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Income
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $65.00
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $280.00
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    $345.00
                  </h5>
                </div>

                <div
                  className="hider col-2 bootstrap-off text-center justify-content-center align-content-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <br />
                  <h5 className="row">
                    <div className="col-5">
                      <button
                        className="portfolioButtonCyan"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Buy
                      </button>
                    </div>
                    <div className="col-5 ">
                      <button
                        className="portfolioButtonBlue"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Sell
                      </button>
                    </div>
                  </h5>
                  <h5 className="row">
                    <div className="col-5 padding-0">
                      <button
                        className="portfolioButtonCyan"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Buy
                      </button>
                    </div>
                    <div className="col-5 padding-0">
                      <button
                        className="portfolioButtonBlue"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Sell
                      </button>
                    </div>
                  </h5>
                  <br />
                  <h5 className="row">
                    <div className="col-10 padding-0">
                      <button
                        className="portfolioButtonGrey"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        Trade on Uniswap
                      </button>
                    </div>
                  </h5>
                </div>
              </div>
            </div>

            <div
              style={{
                marginBottom: "2%",
                borderRadius: "10px",
                "-webkit-box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
                "box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
              }}
            >
              <div
                className="bootstrap-off"
                style={{
                  backgroundColor: "#4AB29D",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "0.5rem",
                }}
              >
                <p className="bootstrap-off text-white fw-bold justify-content-around align-content-around">
                  FIAT BALANCE
                </p>
              </div>
              <div
                className="row bootstrap-off pb-2 pt-2"
                style={{
                  backgroundColor: "white",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                <div
                  className="col-md-2 bootstrap-off p-5"
                  style={{ backgroundColor: "" }}
                >
                  <img
                    alt="PROPRT Logo"
                    src={illustration_rest}
                    className="mx-auto d-block"
                    style={{
                      width: "100%",
                      minWidth: "3rem",
                      maxWidth: "13rem",
                    }}
                  />
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    FIAT
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    AUD
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    OTHER
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Number
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $3,000
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $3,000
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Value
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $1,300
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $5,600
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    $6,900
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center p-0 pt-5 pb-5 mx-auto"
                  style={{
                    padding: 0,
                    backgroundColor: "",
                    // 'borderRadius': '10px',
                    // border: '1px solid #4AB29D',
                    // marginLeft: '1%',
                    // marginBottom: '1%',
                  }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    <br />
                  </h5>
                </div>

                <div
                  className="hider col-2 bootstrap-off text-center justify-content-center align-content-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <br />
                  <h5 className="row">
                    <div className="col-5">
                      <button
                        className="portfolioButtonCyan"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Buy
                      </button>
                    </div>
                    <div className="col-5 ">
                      <button
                        className="portfolioButtonBlue"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Sell
                      </button>
                    </div>
                  </h5>
                  <h5 className="row">
                    <div className="col-5 padding-0">
                      <button
                        className="portfolioButtonCyan"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Buy
                      </button>
                    </div>
                    <div className="col-5 padding-0">
                      <button
                        className="portfolioButtonBlue"
                        type="button"
                        aria-expanded="false"
                        style={{ width: "100%" }}
                      >
                        Sell
                      </button>
                    </div>
                  </h5>
                  <br />
                  <h5 className="row">
                    <div className="col-10 padding-0">
                      <button
                        className="portfolioButtonGrey"
                        type="button"
                        style={{ width: "100%" }}
                      >
                        Trade on Uniswap
                      </button>
                    </div>
                  </h5>
                </div>
              </div>
            </div>

            <div
              style={{
                marginBottom: "2%",
                borderRadius: "10px",
                "-webkit-box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
                "box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
              }}
            >
              <div
                className="bootstrap-off"
                style={{
                  backgroundColor: "#4AB29D",
                  borderRadius: "10px 10px 0px 0px",
                  padding: "0.5rem",
                }}
              >
                <p className="bootstrap-off text-white fw-bold justify-content-around align-content-around">
                  WALLET HOLDINGS (Address: {account})
                </p>
              </div>
              <div
                className="row bootstrap-off pb-2 pt-2"
                style={{
                  backgroundColor: "white",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                <div
                  className="col-md-2 bootstrap-off p-5"
                  style={{ backgroundColor: "" }}
                >
                  <img
                    alt="PROPRT Logo"
                    src={illustration_rest}
                    className="mx-auto d-block"
                    style={{
                      width: "100%",
                      minWidth: "3rem",
                      maxWidth: "13rem",
                    }}
                  />
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Token
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    ETH
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  ></h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Number
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    45
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Value
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    $1,300
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    $1,300
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center p-0 pt-5 pb-5 mx-auto"
                  style={{
                    padding: 0,
                    backgroundColor: "",
                    // 'borderRadius': '10px',
                    // border: '1px solid #4AB29D',
                    // marginLeft: '1%',
                    // marginBottom: '1%',
                  }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                  <br />
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    <br />
                  </h5>
                </div>

                <div
                  className="hider col-2 bootstrap-off text-center justify-content-center align-content-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <br />
                  <h5 className="row">
                    <div className="col-5">
                      <br />
                    </div>
                    <div className="col-5 ">
                      <br />
                    </div>
                  </h5>
                  <h5 className="row">
                    <div className="col-5 padding-0">
                      <br />
                    </div>
                    <div className="col-5 padding-0">
                      <br />
                    </div>
                  </h5>
                  <br />
                  <h5 className="row">
                    <div className="col-10 padding-0">
                      <br />
                    </div>
                  </h5>
                </div>
              </div>
            </div>

            <div
              style={{
                marginBottom: "2%",
                borderRadius: "10px",
                "-webkit-box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
                "box-shadow": "5px 5px 15px 5px rgba(0,0,0,0.25)",
              }}
            >
              <div
                className="row bootstrap-off pb-2 pt-2"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(240,254,251,1) 0%, rgba(255,255,255,1) 40%)",
                  borderRadius: "10px 10px 10px 10px",
                }}
              >
                <div
                  className="col-md-2 bootstrap-off p-5"
                  style={{ backgroundColor: "" }}
                ></div>

                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto "
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    Total
                  </h5>
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  ></h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto "
                  style={{}}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto "
                  style={{}}
                >
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "400", color: "grey" }}
                  >
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto "
                  style={{ backgroundColor: "" }}
                >
                  <h5
                    className="portfolio-text"
                    style={{ fontWeight: "bold", color: "" }}
                  >
                    $1,300
                  </h5>
                </div>

                <div
                  className="col-1 bootstrap-off text-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                </div>
                <div
                  className="col-1 bootstrap-off text-center p-0 pt-5 pb-5 mx-auto"
                  style={{
                    padding: 0,
                    backgroundColor: "",
                    // 'borderRadius': '10px',
                    // border: '1px solid #4AB29D',
                    // marginLeft: '1%',
                    // marginBottom: '1%',
                  }}
                >
                  <h5 className="portfolio-text" style={{ fontWeight: "bold" }}>
                    <br />
                  </h5>
                </div>

                <div
                  className="hider col-2 bootstrap-off text-center justify-content-center align-content-center pt-5 pb-5 mx-auto"
                  style={{ backgroundColor: "" }}
                >
                  <br />
                </div>
              </div>
            </div>

            {/* important */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
