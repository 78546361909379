import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db, logout } from "../components/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import Navbar from "../components/Navbar";

function TestLogedin() {
  const [user, loading, error] = useAuthState(auth);
  const [accountDetails, setAccountDetails] = useState({});
  const navigate = useNavigate();

  // const { state } = useLocation();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      if (data.type === "user") {
        setAccountDetails({
          name: data.firstName + " " + data.lastName,
          email: data.email,
          type: data.type,
        });
      } else {
        setAccountDetails({
          name: data.firstName + " " + data.lastName,
          email: data.email,
          type: data.type,
          agency: data.agencyName,
        });
      }

      // setName(data.firstName + " " + data.lastName);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
  }, [user, loading]);

  return (
    <div>
      <Navbar />
      <section
        style={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontWeight: "bold", paddingTop: "35vh" }}>
          Welcome {accountDetails.name}{" "}
          {accountDetails.type === "agent" && <>from {accountDetails.agency}</>}
        </h1>

        <h1 style={{ fontWeight: "bold", color: "#48B29D", paddingTop: "5vh" }}>
          This will be {accountDetails.type} dashboard screen.
        </h1>

        <h4>{user?.email}</h4>

        <h3 className="mt-5">
          Thank you for your registration, there is nothing in here yet. <br />
          Come back soon!
        </h3>

        <button
          onClick={() => {
            console.log(user);
          }}
          className="login-input mt-5"
        >
          test print user details
        </button>
        <br />

        <button onClick={logout} className="login-input mt-5">
          Logout
        </button>
        <div className="pt-1"></div>
      </section>
    </div>
  );
}
export default TestLogedin;
