import React from "react";
import Hero from "./hero";
import hero from "../../img/LandingPage/hero02b_60grad.jpg";
import botomHero from "../../img/Marketplace/houses_bg02.jpg";

export default function ProprtFounder() {
  return (
    <section
      style={{
        backgroundImage: `url(${hero}), url(${botomHero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "top, bottom",
      }}
      className="min-h-screen"
    >
      <Hero />
    </section>
  );
}
