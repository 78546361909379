import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../../components/firebase";

const PasswordResetPage = () => {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);
  return (
    <div>
      <Navbar />
      <section
        style={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontWeight: "bold", paddingTop: "35vh" }}>
          Reset password
        </h1>
        <div className="pt-1">
          <input
            className="login-input"
            type="email"
            placeholder="Email"
            aria-label="Search"
            value={email}
            style={{
              borderColor: "grey",
              borderWidth: "0.1px",
              borderRadius: "9px",
              width: "30%",
              height: "40px",
            }}
            onChange={(e) => {
              setEmail(e.target.value.toString());
            }}
          />
          <br />

          <br />
          <button
            // type="submit"
            id="landing-cyan-header-button"
            className="col-md mt-4 fw-bold headerButtonCyan"
            style={{ fontSize: 15 }}
            onClick={() => sendPasswordReset(email)}
          >
            Send password reset email
          </button>
        </div>
      </section>
    </div>
  );
};

export default PasswordResetPage;
