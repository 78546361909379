import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import hero from "../../img/LandingPage/hero02b_60grad.jpg";
import botomHero from "../../img/Marketplace/houses_bg02.jpg";
import Hero from "./hero";
import TextTransition, { presets } from "react-text-transition";
import Seller from "./seller";
import Buyer from "./buyer";
import Agent from "./agent";
import Tenants from "./tenants";
import Footer from "../../components/Footer";
import header_img from "./img/proprt-fan.png";

export default function BenefitsPage() {
  const titleLeft = ["Crypto", "Property"];
  const tileRight = ["Community", "Simplified"];
  const [index, setIndex] = React.useState(0);
  const [indexRight, setIndexRight] = React.useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      6000 // every 3 seconds
    );
    const intervalIdRight = setInterval(
      () => setIndexRight((indexRight) => indexRight + 1),
      4000 // every 3 seconds
    );
    return () => {
      clearTimeout(intervalId);
      clearTimeout(intervalIdRight);
    };
  }, []);

  const [selected, setSelected] = useState("Seller");

  return (
    <div
      className="h-screen  pt-20 min-h-screen transition-all duration-300"
      style={{
        backgroundImage: `url(${hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "top, bottom",
      }}
    >
      <Navbar />
      {/* Hero */}
      <section className="text-gray-600 body-font mb-0 md:mb-20">
        <div className="container mx-auto flex px-5 md:py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-centerr">
            <div className=" w-full h-full py-10  hidden md:block">
              <p className="fw-bold text-5xl text-white flex flex-wrap md:justify-start justify-center drop-shadow-xl subpixel-antialiased space-x-5">
                <div>
                  <TextTransition
                    springConfig={presets.molasses}
                    inline={true}
                    className="inline-block align-text-bottom"
                  >
                    {titleLeft[index % titleLeft.length]}
                  </TextTransition>
                </div>
                <div>
                  <span className="mx-0 text-2xl md:text-5xl font-medium inline-block align-text-bottom">
                    Investing
                  </span>
                </div>
                <div>
                  <TextTransition
                    springConfig={presets.molasses}
                    direction="down"
                    inline={true}
                    className="inline-block align-text-bottom"
                  >
                    <div className="flex align-middle">
                      {tileRight[indexRight % tileRight.length]}
                    </div>
                  </TextTransition>
                </div>
              </p>
            </div>
            <p className="mb-24 leading-relaxed text-2xl font-semibold">
              The marketplace to sell or buy a{" "}
              <span className="bg-[#2276C9] text-white rounded p-1 mx-1 font-bold">
                piece
              </span>{" "}
              of property
            </p>

            <div className="flex justify-between space-x-1 md:space-x-2 w-2/3">
              <SelectorButton
                selected={selected}
                setSelected={setSelected}
                text="Seller"
              />
              <SelectorButton
                selected={selected}
                setSelected={setSelected}
                text="Agent"
              />
              <SelectorButton
                selected={selected}
                setSelected={setSelected}
                text="Buyer"
              />
            </div>
            <p className="leading-relaxed text-base mt-3">
              Compare the benefits of PROPRT.io with the old way of Real Estate
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={header_img}
            />
          </div>
        </div>
      </section>

      {/*  */}

      <div className=" transition-all duration-500">
        {selected === "Seller" && <Seller />}
        {selected === "Agent" && <Agent />}
        {selected === "Buyer" && <Buyer />}
        {selected === "Tenants" && <Tenants />}
      </div>
      <div className="flex justify-between space-x-1 md:space-x-2 md:w-1/3 mb-20 mx-auto px-3 md:px-0">
        <SelectorButton
          selected={selected}
          setSelected={setSelected}
          text="Seller"
        />
        <SelectorButton
          selected={selected}
          setSelected={setSelected}
          text="Agent"
        />
        <SelectorButton
          selected={selected}
          setSelected={setSelected}
          text="Buyer"
        />
      </div>
      <Footer />
    </div>
  );
}

const SelectorButton = ({ selected, setSelected, text }) => {
  return (
    <button
      className={`${
        selected === text
          ? "bg-[#2276C9] text-white"
          : "bg-gray-100 text-gray-700"
      } inline-flex items-center border-0 py-2 px-6 focus:outline-none hover:bg-blue-300 rounded-full text-lg transition-all duration-300`}
      onClick={() => setSelected(text)}
    >
      {text}
    </button>
  );
};
