import React, { useState } from "react";

export default function CardPage() {
  const [isShown01, setIsShown01] = useState(false);
  const [isShown02, setIsShown02] = useState(false);
  const [isShown03, setIsShown03] = useState(false);

  return (
    <div
      className="text-center row"
      style={{ paddingTop: "20vh", paddingLeft: "25vw" }}
    >
      <div
        id="theCard"
        className="bootstrap-off"
        style={{
          width: "19rem",
          height: "28rem",
          borderRadius: "25px",
          backgroundColor: "white",
          marginLeft: "5rem",
          cursor: "pointer",
          "-webkit-box-shadow": isShown01
            ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
            : "5px 5px 15px 5px rgba(0,0,0,0.25)",
          "box-shadow": isShown01
            ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
            : "5px 5px 15px 5px rgba(0,0,0,0.25)",
          transition: "0.3s",
        }}
        onMouseEnter={() => setIsShown01(true)}
        onMouseLeave={() => setIsShown01(false)}
      >
        <div
          className="bootstrap-off"
          style={{
            paddingTop: "5px",
            paddingLeft: "16px",
            backgroundColor: "#2276C9",
            width: "100%",
            height: "15%",
            borderRadius: "25px 25px 0px 0px",
          }}
        >
          <p
            style={{ margin: 0, padding: 0, color: "white", fontWeight: "400" }}
          >
            ARN01
          </p>
          <p
            style={{
              margin: 0,
              padding: 0,
              color: "white",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Arncliffe NSW
          </p>
        </div>
        <div
          className="bootstrap-off text-center center"
          style={{
            alignContent: "center",
            backgroundColor: "salmon",
            width: "100%",
            height: "30%",
          }}
        >
          <img
            src="https://www.realestate.com.au/blog/images/1600x900-fit,progressive,format=webp/2021/09/20150014/capi_f74b8c7a85aae0bd2e9966d4b7b740df_902643f801358791de7342d35d2217d2.jpeg"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div
          className="bootstrap-off"
          style={{
            alignContent: "center",
            backgroundColor: "white",
            width: "100%",
            height: "42%",
          }}
        >
          <div
            className="row text-center"
            style={{ margin: 0, padding: 0, marginTop: "3px" }}
          >
            <div
              className="col-6"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
                Rental Yield
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 10.5,
                }}
              >
                (NET)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 27,
                }}
              >
                5.5%
              </p>
            </div>
            <div className="col-6" style={{ margin: 0, padding: 0 }}>
              <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
                Capital Gain
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 10.5,
                }}
              >
                (HISTORIC)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 27,
                }}
              >
                6.2%
              </p>
            </div>
          </div>
          <div
            className="text-center"
            style={{ margin: 0, padding: 0, marginTop: "20px" }}
          >
            <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
              PROPRT Price
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontWeight: "400",
                fontSize: 10.5,
              }}
            >
              (Property Token)
            </p>
            <p
              style={{ margin: 0, padding: 0, fontWeight: "700", fontSize: 27 }}
            >
              $0.5
            </p>
          </div>
        </div>
        <div
          className="bootstrap-off text-center"
          style={{
            alignContent: "center",
            backgroundColor: "#4AB29D",
            width: "100%",
            height: "13%",
            borderRadius: "0px 0px 25px 25px",
            paddingTop: "3.5%",
          }}
        >
          <p style={{ fontSize: "20px", fontWeight: "600", color: "white" }}>
            View Property
          </p>
        </div>
      </div>
      <div
        id="theCard"
        className="bootstrap-off"
        style={{
          width: "19rem",
          height: "28rem",
          borderRadius: "25px",
          backgroundColor: "white",
          marginLeft: "0rem",
          cursor: "pointer",
          "-webkit-box-shadow": isShown02
            ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
            : "5px 5px 15px 5px rgba(0,0,0,0.25)",
          "box-shadow": isShown02
            ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
            : "5px 5px 15px 5px rgba(0,0,0,0.25)",
          transition: "0.3s",
        }}
        onMouseEnter={() => setIsShown02(true)}
        onMouseLeave={() => setIsShown02(false)}
      >
        <div
          className="bootstrap-off"
          style={{
            paddingTop: "5px",
            paddingLeft: "16px",
            backgroundColor: "#2276C9",
            width: "100%",
            height: "15%",
            borderRadius: "25px 25px 0px 0px",
          }}
        >
          <p
            style={{ margin: 0, padding: 0, color: "white", fontWeight: "400" }}
          >
            ARN02
          </p>
          <p
            style={{
              margin: 0,
              padding: 0,
              color: "white",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Arncliffe NSW
          </p>
        </div>
        <div
          className="bootstrap-off text-center center"
          style={{
            alignContent: "center",
            backgroundColor: "salmon",
            width: "100%",
            height: "30%",
          }}
        >
          <img
            src="https://newmarketrandwick.com.au/wp-content/themes/newmarket-randwick/images/1_Masterplan/InteractiveMap/1-newmarketresidences.jpg"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div
          className="bootstrap-off"
          style={{
            alignContent: "center",
            backgroundColor: "white",
            width: "100%",
            height: "42%",
          }}
        >
          <div
            className="row text-center"
            style={{ margin: 0, padding: 0, marginTop: "3px" }}
          >
            <div
              className="col-6"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
                Rental Yield
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 10.5,
                }}
              >
                (NET)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 27,
                }}
              >
                5.5%
              </p>
            </div>
            <div className="col-6" style={{ margin: 0, padding: 0 }}>
              <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
                Capital Gain
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 10.5,
                }}
              >
                (HISTORIC)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 27,
                }}
              >
                6.2%
              </p>
            </div>
          </div>
          <div
            className="text-center"
            style={{ margin: 0, padding: 0, marginTop: "20px" }}
          >
            <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
              PROPRT Price
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontWeight: "400",
                fontSize: 10.5,
              }}
            >
              (Property Token)
            </p>
            <p
              style={{ margin: 0, padding: 0, fontWeight: "700", fontSize: 27 }}
            >
              $0.5
            </p>
          </div>
        </div>
        <div
          className="bootstrap-off text-center"
          style={{
            alignContent: "center",
            backgroundColor: "#4AB29D",
            width: "100%",
            height: "13%",
            borderRadius: "0px 0px 25px 25px",
            paddingTop: "3.5%",
          }}
        >
          <p style={{ fontSize: "20px", fontWeight: "600", color: "white" }}>
            View Property
          </p>
        </div>
      </div>
      <div
        id="theCard"
        className="bootstrap-off"
        style={{
          width: "19rem",
          height: "28rem",
          borderRadius: "25px",
          backgroundColor: "white",
          marginLeft: "-30rem",
          marginTop: "1.5rem",
          cursor: "pointer",
          "-webkit-box-shadow": isShown03
            ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
            : "5px 5px 15px 5px rgba(0,0,0,0.25)",
          "box-shadow": isShown03
            ? "1px 1px 15px 5px rgba(74, 178, 157, 1)"
            : "5px 5px 15px 5px rgba(0,0,0,0.25)",
          transition: "0.3s",
        }}
        onMouseEnter={() => setIsShown03(true)}
        onMouseLeave={() => setIsShown03(false)}
      >
        <div
          className="bootstrap-off"
          style={{
            paddingTop: "5px",
            paddingLeft: "16px",
            backgroundColor: "#2276C9",
            width: "100%",
            height: "15%",
            borderRadius: "25px 25px 0px 0px",
          }}
        >
          <p
            style={{ margin: 0, padding: 0, color: "white", fontWeight: "400" }}
          >
            ARN03
          </p>
          <p
            style={{
              margin: 0,
              padding: 0,
              color: "white",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Brisbane QLD
          </p>
        </div>
        <div
          className="bootstrap-off text-center center"
          style={{
            alignContent: "center",
            backgroundColor: "salmon",
            width: "100%",
            height: "30%",
          }}
        >
          <img
            src="https://passiveincomemd.com/wp-content/uploads/2020/01/investing_in_apartment_buildings-696x389.jpg"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div
          className="bootstrap-off"
          style={{
            alignContent: "center",
            backgroundColor: "white",
            width: "100%",
            height: "42%",
          }}
        >
          <div
            className="row text-center"
            style={{ margin: 0, padding: 0, marginTop: "3px" }}
          >
            <div
              className="col-6"
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
                Rental Yield
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 10.5,
                }}
              >
                (NET)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 27,
                }}
              >
                5.5%
              </p>
            </div>
            <div className="col-6" style={{ margin: 0, padding: 0 }}>
              <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
                Capital Gain
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "400",
                  fontSize: 10.5,
                }}
              >
                (HISTORIC)
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: "700",
                  fontSize: 27,
                }}
              >
                6.2%
              </p>
            </div>
          </div>
          <div
            className="text-center"
            style={{ margin: 0, padding: 0, marginTop: "20px" }}
          >
            <p style={{ margin: 0, padding: 0, fontWeight: "500" }}>
              PROPRT Price
            </p>
            <p
              style={{
                margin: 0,
                padding: 0,
                fontWeight: "400",
                fontSize: 10.5,
              }}
            >
              (Property Token)
            </p>
            <p
              style={{ margin: 0, padding: 0, fontWeight: "700", fontSize: 27 }}
            >
              $0.5
            </p>
          </div>
        </div>
        <div
          className="bootstrap-off text-center"
          style={{
            alignContent: "center",
            backgroundColor: "#4AB29D",
            width: "100%",
            height: "13%",
            borderRadius: "0px 0px 25px 25px",
            paddingTop: "3.5%",
          }}
        >
          <p style={{ fontSize: "20px", fontWeight: "600", color: "white" }}>
            View Property
          </p>
        </div>
      </div>
    </div>
  );
}
