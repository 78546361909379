import React from "react";
import illustration_female from "../img/illustration/undraw_female_avatar_w3jk-2.svg";
import illustration_male from "../img/illustration/undraw_male_avatar_323b-2.svg";
import ic_linkedin from "../img/icons/linkedin.svg";

import te_frank from "./img/team/frank.jpeg";
import te_rafi from "./img/team/rafi.jpeg";
import te_dennis from "./img/team/dennis.jpeg";
import te_karlo from "./img/team/karlo.jpeg";
import te_ron from "./img/team/ron.jpeg";
import te_ronnie from "./img/team/ronnie.jpeg";
import te_alan from "./img/team/alan.jpeg";
import te_aakash from "./img/team/aakash.jpg";
import te_stuart from "./img/team/stuart.jpeg";
import te_arve from "./img/team/arve.jpeg";
import te_adrian from "./img/team/adrian.jpeg";

export default function TeamMember() {
  return (
    <section className="mt-5 pt-5" id="team">
      <hr className="line-section mx-auto" style={{ marginBottom: "9.5%" }} />
      <h1
        className="text-center text-3xl font-semibold"
        style={{ color: "#2276C9", fontFamily: "Montserrat", fontWeight: 600 }}
      >
        Team Members
      </h1>
      <p
        className="text-center mt-4"
        style={{ marginBottom: "0rem", color: "grey" }}
      >
        Meet our team members.
      </p>
      <div className="container row mx-auto md:mt-5">
        {teams.slice(0, 3).map((team) => ProfileCard(team))}
      </div>

      <div className="container row mx-auto md:mt-md-5">
        {teams.slice(3, 6).map((team) => ProfileCard(team))}
      </div>

      <div className="container row mx-auto md:mt-md-5">
        {teams.slice(6, 9).map((team) => ProfileCard(team))}
      </div>

      <div className="container row mx-auto md:mt-md-5">
        {teams.slice(9, 12).map((team) => ProfileCard(team))}
      </div>

      <div style={{ minHeight: "5rem" }}></div>
    </section>
  );
}

const ProfileCard = ({ name, description, linkedin_url, profile_picture }) => {
  return (
    <div className="col-lg mb-4 row" style={{}}>
      <div
        className="col-4 text-center hover:cursor-pointer hover:scale-125 transform transition duration-500 ease-in-out"
        onClick={() => {
          window.open(linkedin_url, "_blank");
        }}
      >
        <img
          className="mb-2 shadow-lg mx-auto"
          src={profile_picture}
          style={{
            height: "6rem",
            marginTop: "5%",
            borderRadius: "50%",
            filter: "grayscale(100%)",
          }}
          alt=""
        />
        <p className="fw-bold mx-auto">{name}</p>
      </div>
      <p
        className="col text-justify"
        style={{
          fontSize: "13px",
          verticalAlign: "middle",
          display: "table-cell",
        }}
      >
        {description}
        <br />
        <a href={linkedin_url} rel="noreferrer" target="_blank">
          <img src={ic_linkedin} alt="" style={{ height: "1rem" }} />
        </a>
      </p>
    </div>
  );
};

const teams = [
  {
    name: "Frank",
    description:
      "Over 25 years’ experience as a property investor, property manager with a real estate licence and a BSc in Computer Science. Frank has a keen interest in solidity programming and web3 development.",
    linkedin_url: "https://www.linkedin.com/in/frankteunissenproprt/",
    profile_picture: te_frank,
  },
  {
    name: "Rafi",
    description:
      "Brings in depth coding and project skills to our team across range of software platforms and a particular interest in web3 development and NFT’s.",
    linkedin_url: "https://www.linkedin.com/in/wiratmoko/",
    profile_picture: te_rafi,
  },
  {
    name: "Stuart",
    description:
      "Stuart has a background in a information systems, he holds formal qualifications in IT and has over 20 years of real world industry experience. ",
    linkedin_url: "https://www.linkedin.com/in/bondstuart/",
    profile_picture: te_stuart,
  },
  {
    name: "Arve",
    description:
      "Arve is highly experienced Senior Software Engineer with demonstrated achievements working in the higher education industry. With a strong Full-stack development experience and engineering professional backed by a master degree.",
    linkedin_url: "https://www.linkedin.com/in/arvesolland/",
    profile_picture: te_arve,
  },
  {
    name: "Aakash",
    description:
      "A MSc in Computer Science and experience in product development, support and systems analysis makes Aakash a valuable team member.",
    linkedin_url: "https://www.linkedin.com/in/aakashjadhav/",
    profile_picture: te_aakash,
  },
  {
    name: "Karlo",
    description:
      "Combining a BSc in Computer Science and experience in a range of IT projects including customer experience, SEO and back-end IT support. ",
    linkedin_url: "https://www.linkedin.com/in/karlocamena/",
    profile_picture: te_karlo,
  },
  {
    name: "Ronnie",
    description:
      "Over 25 years’ experience as a Real Estate Investor, Business broker and agent. Combined with strong sales and marketing skills makes Ronnie a key member of the team.",
    linkedin_url: "https://www.linkedin.com/in/ronnie-hendriks-279a76231/",
    profile_picture: te_ronnie,
  },
  {
    name: "Ron",
    description:
      "With a BSc in Computer Science and experience as a full-stack developer across a range of languages and projects makes Ron critical to our Tech team.",
    linkedin_url: "https://www.linkedin.com/in/ron-michael-dionaldo-3b959846/",
    profile_picture: te_ron,
  },
  {
    name: "James",
    description:
      "Our legal team is spearheaded by our Corporate Lawyer with extensive experience in regulatory oversight, governance and bringing corporate projects to the market.",
    linkedin_url: "",
    profile_picture: illustration_male,
  },
  {
    name: "Alan",
    description:
      "Bringing in depth business analysis, ICT and system knowledge to the team. Over 25 years of experience in a range of large corporations and government agencies.",
    linkedin_url:
      "https://www.linkedin.com/in/alan-bergsma-cpd-pmp-msp-rmp-m-o-r-prince2-agile-a1b8256/",
    profile_picture: te_alan,
  },
  {
    name: "Dennis",
    description:
      "Building on his years of experience as a designer, with “out-of-the-box” design and branding insights, combined with in depth knowledge in developing UX.",
    linkedin_url: "https://www.linkedin.com/in/pixvox/",
    profile_picture: te_dennis,
  },
  {
    name: "Adrian",
    description:
      "Corporate and Commercial Lawyer with a mixture of inhouse and private practice experience. His experience covers giving advise institutional and private clients in regard to their legal, trust, compliance, fund & product design and liability issues.",
    linkedin_url: "https://www.linkedin.com/in/adrian-edwards-0a03b44/",
    profile_picture: te_adrian,
  },
];
