import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db, logout } from "../components/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import Navbar from "../components/Navbar";

const SeedRoundPage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [accountDetails, setAccountDetails] = useState({});
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const [totalSeed, setTotalSeed] = useState(200);

  // const { state } = useLocation();

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();

      if (data.type === "user") {
        setAccountDetails({
          name: data.firstName + " " + data.lastName,
          email: data.email,
          type: data.type,
        });
      } else {
        setAccountDetails({
          name: data.firstName + " " + data.lastName,
          email: data.email,
          type: data.type,
          agency: data.agencyName,
        });
      }

      // setName(data.firstName + " " + data.lastName);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    fetchUserName();
    setEmail(user.email);
  }, [user, loading]);

  return (
    <div>
      <Navbar />
      <section
        style={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          textAlign: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "bold",
            paddingTop: "15vh",
          }}
        >
          Thank you for participating <br />
          in our first seed round <br /> {accountDetails.name}{" "}
          {accountDetails.type === "agent" && <>from {accountDetails.agency}</>}
        </h1>

        <h1 style={{ fontWeight: "bold", color: "#48B29D", paddingTop: "5vh" }}>
          Please input on how much you would <br />
          like to invest in the project.
        </h1>

        <button
          id="landing-cyan-header-button"
          className="col-md mt-4 fw-bold headerButtonCyan me-3"
          style={{ fontSize: 15 }}
          onClick={() => {
            setTotalSeed(totalSeed - 1);
          }}
        >
          -
        </button>
        <input
          className="login-input"
          type="number"
          placeholder="200"
          aria-label="Search"
          style={{
            borderColor: "grey",
            borderWidth: "0.1px",
            borderRadius: "9px",
            height: "40px",
            width: "10%",
            minWidth: "8rem",
          }}
          value={totalSeed}
          onChange={(e) => {
            setTotalSeed(e.target.value);
          }}
        />
        <button
          id="landing-cyan-header-button"
          className="col-md mt-4 fw-bold headerButtonCyan ms-3"
          style={{ fontSize: 15 }}
          onClick={() => {
            setTotalSeed(totalSeed + 1);
          }}
        >
          +
        </button>
        <br />
        <input
          className="login-input"
          type="email"
          value={email}
          aria-label="Search"
          style={{
            borderColor: "grey",
            borderWidth: "0.1px",
            borderRadius: "9px",
            height: "40px",
            width: "20%",
            minWidth: "18rem",
          }}
        />
        <br />
        <button
          className="col-md mt-4 fw-bold headerButtonCyan pe-3 ps-3"
          style={{ backgroundColor: "#4F86EC" }}
        >
          Buy
        </button>
        <br />

        <button onClick={logout} className="login-input mt-5">
          Logout
        </button>
        <div className="pt-1"></div>
      </section>
    </div>
  );
};

export default SeedRoundPage;
